import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import React from "react"

import Theme from "@/entity/theme/theme"

const ColorCircularProgress = withStyles({
    root: {
        color: Theme.color.primaryColor,
        margin: "auto",
        overflow: "hidden",
        // height: (props) => props.height,
        // width: (props) => props.width,
    },
})(CircularProgress)

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        "& > * + *": {
            marginLeft: theme.spacing(2),
        },
    },
}))

export default function WaitCircle(props) {
    const classes = useStyles(props)
    return (
        <div className={classes.root}>
            <ColorCircularProgress
                style={{
                    width: props.width,
                    height: props.height,
                }}
            />
        </div>
    )
}
