import { makeStyles } from "@material-ui/core/styles"
import React, { FC } from "react"

import Theme from "@/entity/theme/theme"
import MenuFloatinButtons from "@/presentation/organisms/floatingButton/menuFloatingButtons"
import LabelBottomNavigation from "@/presentation/organisms/footer/labelBottomNavigation"
import TopAppBar from "@/presentation/organisms/header/topAppBar"

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: "#fff",
        fontFamily: Theme.font.fontFamily,
    },
}))

type FrameTemplateProps = {
    loginUser: any
    setLoginUser: () => void
    setIsLoading: () => void
    rangeDate: { startDate: number; endDate: number }
    setRangeDate: (v: { startDate: number; endDate: number }) => void
    memberData: any
    setMemberData: () => void
    setTitle: (v: string) => void
    title: string
    notificationCount: number
    setMonthAllRecords?: (v: any) => void
    distribution?: (v: any) => void
    setDistinction: (v: number) => void
    setRecords: (v: any) => void
    setAttendanceDay: (v: any) => void
    setExpanded: (v: any) => void
    setContacts: (v: any) => void
    setAllAttendanceDay: (v: any) => void
}

const FrameTemplate: FC<FrameTemplateProps> = (props) => {
    const classes = useStyles()
    const {
        loginUser,
        setLoginUser,
        setIsLoading,
        rangeDate,
        setRangeDate,
        memberData,
        setMemberData,
        setTitle,
        title,
        notificationCount,
        setMonthAllRecords,
        distribution,
        setDistinction,
        setRecords,
        setAttendanceDay,
        setExpanded,
        setContacts,
        setAllAttendanceDay,
    } = props

    return (
        <div className={classes.root}>
            {/* 固定のコンポーネント */}
            <TopAppBar
                loginUser={loginUser}
                setLoginUser={setLoginUser}
                setIsLoading={setIsLoading}
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
                memberData={memberData}
                setMemberData={setMemberData}
                setTitle={setTitle}
                title={title}
                notificationCount={notificationCount}
            />
            <MenuFloatinButtons
                loginUser={loginUser}
                memberData={memberData}
                setRecords={setRecords}
                setMonthAllRecords={setMonthAllRecords}
                setDistinction={setDistinction}
                setIsLoading={setIsLoading}
                title={title}
                setTitle={setTitle}
                setRangeDate={setRangeDate}
                distribution={distribution}
                setAttendanceDay={setAttendanceDay}
                setExpanded={setExpanded}
                setContacts={setContacts}
                setAllAttendanceDay={setAllAttendanceDay}
            />
            <LabelBottomNavigation
                loginUser={loginUser}
                setIsLoading={setIsLoading}
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
                memberData={memberData}
                setTitle={setTitle}
                title={title}
            />
        </div>
    )
}

export default FrameTemplate
