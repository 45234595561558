import firebase from "firebase/app"
import "firebase/storage"
import "firebase/database"
var firebaseConfig = {
    apiKey: "AIzaSyD1JB7zUBmr0Wg5NAlyY9zwGQ2Z3Le_DuQ",
    authDomain: "atoko-hitrate.firebaseapp.com",
    databaseURL: "https://atoko-hitrate.firebaseio.com",
    projectId: "atoko-hitrate",
    storageBucket: "atoko-hitrate.appspot.com",
    messagingSenderId: "525896740928",
    appId: "1:525896740928:web:6fa175ee4ea65d9559efa5",
    measurementId: "G-90RD5330EH",
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
export const storage = firebase.storage()
export const firebaseDb = firebase.database()

export default firebase
