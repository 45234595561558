import Backdrop from "@material-ui/core/Backdrop"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Dialog from "@material-ui/core/Dialog"
import Fade from "@material-ui/core/Fade"
import IconButton from "@material-ui/core/IconButton"
import Popper from "@material-ui/core/Popper"
import { makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import ContactSupportIcon from "@material-ui/icons/ContactSupport"
import SchoolIcon from "@material-ui/icons/School"
import React from "react"
import fontStyle from "src/entity/theme/fontStyle"
import questions from "src/images/badgePic/questions.png"
import all_arrow from "src/images/mainPic/all_arrow.png"
import rank0 from "src/images/rankPic/rank0.png"
import rank10 from "src/images/rankPic/rank10.png"
import rank100 from "src/images/rankPic/rank100.png"
import rank15 from "src/images/rankPic/rank15.png"
import rank20 from "src/images/rankPic/rank20.png"
import rank25 from "src/images/rankPic/rank25.png"
import rank30 from "src/images/rankPic/rank30.png"
import rank35 from "src/images/rankPic/rank35.png"
import rank40 from "src/images/rankPic/rank40.png"
import rank45 from "src/images/rankPic/rank45.png"
import rank5 from "src/images/rankPic/rank5.png"
import rank50 from "src/images/rankPic/rank50.png"
import rank55 from "src/images/rankPic/rank55.png"
import rank60 from "src/images/rankPic/rank60.png"
import rank65 from "src/images/rankPic/rank65.png"
import rank70 from "src/images/rankPic/rank70.png"
import rank75 from "src/images/rankPic/rank75.png"
import rank80 from "src/images/rankPic/rank80.png"
import rank85 from "src/images/rankPic/rank85.png"
import rank90 from "src/images/rankPic/rank90.png"
import rank95 from "src/images/rankPic/rank95.png"
import { window } from "ssr-window"

import swalErrorAction from "../../../entity/alert/swalErrorAction"
import { networkError } from "../../../entity/alert/swalErrorList"
import Theme from "../../../entity/theme/theme"
import { userRepository } from "../../../repository/action"
import WaitCircle from "../../atoms/waitCircle"

import Profile from "./profile"

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "left",
        fontSize: "15px",
        padding: "3px",
        fontWeight: "bold",
        color: "white",
        background: "darkseagreen",
        overflow: "hidden",
        zIndex: "1000",
    },
    userinfo: {
        textAlign: "center",
        fontSize: "20px",
        fontWeight: "bold",
        margin: "8px 0px",
        borderRadius: "4px",
        textShadow: "1px 2px 3px #808080",
        display: "flex",
        justifyContent: "center",
        position: "relative",
    },
    abyss: {
        background: Theme.color.primaryColor,
        borderRadius: "3px",
        border: "solid 1px #808080",
        width: "100%",
        overflow: "scroll",
        height: window.innerHeight - 140,
        maxHeight: "690px",
        fontFamily: Theme.font.fontFamily,
    },
    abyssContent: {
        width: "100%",
        fontFamily: Theme.font.fontFamily,
    },
    numSquare: {
        border: "solid 1px grey",
        boxShadow: "inset 0px 1px 2px 2px #ccc",
        marginRight: "10px",
        padding: "7px 12px",
        height: "100%",
    },
    trophySquare: {
        border: "1px grey",
        boxShadow: "inset 0px 1px 2px 2px #ccc",
        padding: "9px 6px",
        display: "flex",
        margin: "auto",
        background: "grey",
        color: "#fff",
    },
    itemTitle: {
        textShadow: "1px 2px 3px #808080",
        background: "darkseagreen",
        width: "100%",
        fontSize: "20px",
        textAlign: "center",
    },

    cardInset: {
        boxShadow: "inset 0px 1px 2px 2px #ccc",
        width: "100%",
        display: "inlineFlex",
        textAlign: "center",
        paddingBottom: "0px!important",
    },
    waitCircle: {
        position: "fixed",
        left: "43%",
        top: "50%",
        zIndex: 10,
    },
    womanBackground: {
        background: "#FFF4F6",
    },
    typography: {
        padding: theme.spacing(2),
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
    },
}))

function genderCompare(a, b) {
    const genreA = a.gender
    const genreB = b.gender

    let comparison = 0
    if (genreA == "woman") {
        comparison = -1
    } else if (genreB == "man") {
        comparison = 1
    }
    return comparison
}

function thisHitRate(records) {
    const allData = {
        hit_arrow: 0,
        all_arrow: 0,
    }
    records.map((data) => {
        allData.hit_arrow += data.hit_arrow
        allData.all_arrow += data.all_arrow
    })
    allData.hit_rate = Math.round((allData.hit_arrow / allData.all_arrow) * 100)
    if (allData.hit_arrow == 0 && allData.all_arrow == 0) {
        allData.hit_rate = 0
        return allData
    } else {
        return allData
    }
}

export default function MemberProfile(props) {
    const { loginUser, memberData, onClose } = props
    const classes = useStyles()
    const [allRecords] = React.useState({ records: [] })
    const [memberRecords, setMemberRecords] = React.useState()
    const [profileOpen, setProfileOpen] = React.useState(false)
    const [user, setUser] = React.useState({})
    const [allRate, setAllRate] = React.useState()
    const [typeNum, setTypeNum] = React.useState()
    const [rankPic] = React.useState([
        rank0,
        rank5,
        rank10,
        rank15,
        rank20,
        rank25,
        rank30,
        rank35,
        rank40,
        rank45,
        rank50,
        rank55,
        rank60,
        rank65,
        rank70,
        rank75,
        rank80,
        rank85,
        rank90,
        rank95,
        rank100,
    ])
    const [, setRankDetailsOpen] = React.useState(true)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [placement, setPlacement] = React.useState()
    const [open, setOpen] = React.useState(false)

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget)
        setOpen(!open)
        setPlacement(newPlacement)
    }

    const handleProfileOpen = (person) => {
        setProfileOpen(!profileOpen)
        //      ////console.log({ person })
        if (person) {
            setUser(person)
        }
    }

    React.useEffect(() => {
        userRepository
            .monthMemberDataFetch(loginUser, memberData)
            .then((data) => {
                let type = []
                let allowMemberData = memberData.filter((value) => {
                    if (value.type !== "ob" && value.permission === "2") {
                        return value
                    }
                })
                const result = data.map((value, index) => {
                    type = [...type, allowMemberData[index].type]
                    let thisMonthRate = thisHitRate(value)
                    let arrow = {
                        thisMonthRate: thisMonthRate,
                        name: allowMemberData[index].name,
                        id: allowMemberData[index].id,
                        type: allowMemberData[index].type,
                        team: allowMemberData[index].team,
                        create_date: allowMemberData[index].create_date,
                        permission: allowMemberData[index].permission,
                        display: allowMemberData[index].display,
                        gender: allowMemberData[index].gender,
                    }
                    return arrow
                })
                //OBを削除しデータをソート
                const filterResult = result.filter((v) => v)
                filterResult.sort(genderCompare)
                filterResult.reverse()
                //全体の的中率を求める
                const allData = {
                    hit_arrow: 0,
                    all_arrow: 0,
                }
                filterResult.forEach((value) => {
                    allData.hit_arrow += value.thisMonthRate.hit_arrow
                    allData.all_arrow += value.thisMonthRate.all_arrow
                })
                if (allData.hit_arrow == 0 && allData.all_arrow == 0) {
                    allData.hit_rate = 0
                } else {
                    allData.hit_rate = Math.round((allData.hit_arrow / allData.all_arrow) * 100)
                }

                //役職の数を数える
                let counts = { ob: 0, member: 0, leaders: 0 }
                for (let i = 0; i < type.length; i++) {
                    let key = type[i]
                    counts[key] = counts[key] ? counts[key] + 1 : 1
                }
                setTypeNum({
                    member: counts["member"],
                    leaders: counts["leaders"],
                    ob: counts["ob"],
                })
                setAllRate(allData.hit_rate)
                setMemberRecords(filterResult)
            })
            .catch(() => {
                swalErrorAction(networkError)
            })
    }, [])

    return (
        <>
            {loginUser && allRecords && memberRecords && (
                <div className={classes.root}>
                    <div className={classes.userinfo}>
                        <div style={fontStyle("bold")}>部員プロフィール</div>
                        <div style={{ right: "0px", top: "-11px", position: "absolute" }}>
                            <IconButton color="secondary" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    <Backdrop
                        open={open}
                        onClick={() => {
                            setOpen(false)
                        }}
                        style={{ zIndex: 300, opacity: "-1" }}
                    />

                    <Popper
                        open={open}
                        anchorEl={anchorEl}
                        placement={placement}
                        transition
                        style={{ zIndex: "200000", width: "255px" }}
                    >
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Card className={classes.typography}>
                                    <span style={{ fontWeight: "bold" }}>
                                        通常練習の記録のみ反映されます
                                    </span>
                                </Card>
                            </Fade>
                        )}
                    </Popper>
                    <Dialog
                        disableBackdropClick
                        disableEscapeKeyDown
                        open={profileOpen}
                        onClose={() => {
                            handleProfileOpen(false)
                        }}
                        fullWidth
                    >
                        <Profile
                            memberData={memberData}
                            loginUser={loginUser}
                            fetchUser={user}
                            onClose={() => {
                                handleProfileOpen(false)
                            }}
                        />
                        {profileOpen && (
                            <div className={classes.waitCircle}>
                                <WaitCircle width={"60px"} height={"60px"} />
                            </div>
                        )}
                    </Dialog>
                    <div className={classes.abyss}>
                        <div style={{ display: "flex" }}>
                            <div
                                style={{ display: "flex", justifyContent: "start", margin: "4px" }}
                            >
                                <SchoolIcon fontSize="large" />
                                <div style={{ margin: "2px" }}>
                                    <div style={{ fontSize: "24px" }}>{loginUser.team}</div>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginLeft: "auto",
                                marginTop: "10px",
                                width: "140px",
                            }}
                        >
                            <div style={{ margin: "-15px -46px 2px 2px", fontSize: "20px" }}>
                                <div style={{ fontSize: "13px", marginBottom: "5px" }}>
                                    {" "}
                                    今月の全体的中率
                                    <IconButton
                                        style={{ color: "#fff", top: "-9px", padding: "0px" }}
                                        onClick={handleClick("bottom-start", 1)}
                                    >
                                        <ContactSupportIcon style={{ fontSize: "smaller" }} />
                                    </IconButton>
                                </div>
                                <div style={{ margin: "10px 26px" }}>{allRate}%</div>
                            </div>
                            <div>
                                <img src={all_arrow} alt="all_arrow" />
                            </div>
                        </div>
                        <div style={{ margin: "4px" }}>
                            {/* <div style={{ fontSize: "20px" }}>
                              合計：{typeNum.member + typeNum.leaders + typeNum.ob}人
                                </div>   */}
                            <div style={{ fontSize: "15px" }}>
                                現役メンバー：{typeNum.member + typeNum.leaders}人
                                <div style={{ fontSize: "12px" }}>
                                    ●通常部員：{typeNum.member}人
                                </div>
                                <div style={{ fontSize: "12px" }}>
                                    ●幹部部員：{typeNum.leaders}人
                                </div>
                            </div>
                            {/* <div style={{ fontSize: "12px", margin: "4px" }}>↓通常部員の方は、表示設定OFFのユーザーの記録を見ることはできません</div> */}
                        </div>
                        <div className={classes.itemTitle}>通常練習 今月</div>
                        <div className={classes.abyssContent}>
                            {memberRecords.map((value, i) => {
                                ////////console.log(bestMonth);
                                let ranking

                                let num = 0
                                rankPic.forEach((v) => {
                                    if (value.thisMonthRate.all_arrow == 0) {
                                        ranking = (
                                            <img
                                                src={questions}
                                                style={{ width: "60%", maxWidth: "60px" }}
                                            />
                                        )
                                    } else if (
                                        num <= value.thisMonthRate.hit_rate &&
                                        value.thisMonthRate.hit_rate < num + 5
                                    ) {
                                        ranking = (
                                            <img
                                                src={v}
                                                style={{ width: "70%", maxWidth: "60px" }}
                                                alt={v}
                                                onClick={() => {
                                                    setRankDetailsOpen(true)
                                                }}
                                            />
                                        )
                                    }
                                    num = num + 5
                                })

                                return (
                                    <Card
                                        key={"memerProfile" + i}
                                        style={{ margin: "4px" }}
                                        className={
                                            value.gender == "woman" && classes.womanBackground
                                        }
                                    >
                                        <CardContent className={classes.cardInset}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                                onClick={() => {
                                                    if (
                                                        loginUser.type == "leaders" ||
                                                        loginUser.type == "ob" ||
                                                        (value.display == "true" &&
                                                            loginUser.type == "member")
                                                    ) {
                                                        handleProfileOpen(value)
                                                    }
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        margin: "2px auto 0px 0px",
                                                        textAlign: "left",
                                                        width: "110px",
                                                    }}
                                                >
                                                    <div style={{ fontSize: "14px" }}>
                                                        {value.name}
                                                    </div>
                                                    {value.type == "member" && (
                                                        <div style={{ fontSize: "12px" }}>
                                                            通常部員
                                                        </div>
                                                    )}
                                                    {value.type == "leaders" && (
                                                        <div style={{ fontSize: "12px" }}>
                                                            幹部部員
                                                        </div>
                                                    )}
                                                    {value.type == "ob" && (
                                                        <div style={{ fontSize: "12px" }}>OB</div>
                                                    )}
                                                    {value.type == "administrator" && (
                                                        <div style={{ fontSize: "12px" }}>
                                                            管理者
                                                        </div>
                                                    )}
                                                </div>
                                                {loginUser.type == "leaders" ||
                                                loginUser.type == "ob" ||
                                                (value.display == "true" &&
                                                    loginUser.type == "member") ? (
                                                    <div
                                                        style={{
                                                            margin: "0px auto 4px",
                                                            width: "35%",
                                                        }}
                                                    >
                                                        {ranking}
                                                    </div>
                                                ) : (
                                                    <div style={{ margin: "13px auto 28px" }}>
                                                        表示設定がOFF
                                                    </div>
                                                )}
                                            </div>
                                        </CardContent>
                                    </Card>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
