import BottomNavigation from "@material-ui/core/BottomNavigation"
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction"
import { makeStyles } from "@material-ui/core/styles"
import ContactMailIcon from "@material-ui/icons/ContactMail"
import GroupIcon from "@material-ui/icons/Group"
import HomeIcon from "@material-ui/icons/Home"
import MenuBookIcon from "@material-ui/icons/MenuBook"
import { navigate } from "gatsby"
import React from "react"
import { window } from "ssr-window"

import swalErrorAction from "../../../entity/alert/swalErrorAction"
import { networkError } from "../../../entity/alert/swalErrorList"
import {
    recordsRepository,
    memberRecordsRepository,
    attendanceRepository,
    contactRepository,
} from "../../../repository/action"

import Theme from "@/entity/theme/theme"

export default function LabelBottomNavigation(props) {
    const { loginUser, memberData, setIsLoading, setTitle } = props

    const useStyles = makeStyles({
        root: {
            width: "100%",
            position: "fixed",
            bottom: "0px",
            height: "10%",
            maxHeight: "80px",
            color: "white",
            zIndex: 10,
        },
        actionItemStyles: {
            minWidth: "0px",
            padding: "6px 6px 8px",
            "&$selected": {
                color: Theme.color.primaryColor,
            },
        },
        selected: {},
    })
    const classes = useStyles()

    const [, setValue] = React.useState("")

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const toMain = () => {
        let pathname = window.location.pathname.split("/")
        if (!(pathname[1] == "")) {
            setIsLoading(true)
            contactRepository
                .contactsFetch(loginUser)
                .then((data) => {
                    let contactsjson = JSON.stringify(data)
                    localStorage.setItem(["contacts"], [contactsjson])
                    navigate("/", {
                        state: { loginUser, memberData, contacts: data.reverse() },
                    })
                })
                .catch(() => {
                    swalErrorAction(networkError)
                })
        }
    }

    const toRecords = () => {
        let pathname = window.location.pathname.split("/")
        if (!(pathname[1] == "records")) {
            setIsLoading(true)
            setTitle("データ受信中...")
            recordsRepository
                .allRecordsFetch(loginUser)
                .then((data) => {
                    navigate(`/records`, {
                        state: { loginUser, memberData, monthAllRecords: data, distinction: 0 },
                    })
                })
                .catch(() => {
                    swalErrorAction(networkError)
                })
        }
    }
    const toMemberRecords = () => {
        let pathname = window.location.pathname.split("/")
        if (!(pathname[1] == "memberRecords")) {
            setIsLoading(true)
            setTitle("データ受信中...")
            memberRecordsRepository
                .allMemberRecordsFetch(loginUser, memberData)
                .then((data) => {
                    navigate(`/memberRecords`, {
                        state: { loginUser, memberData, allMemberRecords: data, distinction: 0 },
                    })
                })
                .catch(() => {
                    swalErrorAction(networkError)
                })
        }
    }

    const toAttendance = (rec_name) => {
        let pathname = window.location.pathname.split("/")
        if (!(pathname[1] == rec_name)) {
            setIsLoading(true)
            setTitle("データ受信中...")
            attendanceRepository
                .attendanceDataFetch(loginUser, memberData)
                .then((data) => {
                    navigate(`/${rec_name}`, { state: { loginUser, memberData, [rec_name]: data } })
                })
                .catch(() => {
                    swalErrorAction(networkError)
                })
        }
    }
    return (
        <BottomNavigation
            value={window.location.pathname.split("/")[1]}
            onChange={handleChange}
            className={classes.root}
        >
            <BottomNavigationAction
                classes={{
                    root: classes.actionItemStyles,
                    selected: classes.selected,
                }}
                showLabel
                onClick={() => {
                    toMain()
                }}
                label="連絡"
                value=""
                icon={<HomeIcon color={loginUser && memberData ? "inherit" : "disabled"} />}
                disabled={!loginUser && !memberData}
            />
            <BottomNavigationAction
                classes={{
                    root: classes.actionItemStyles,
                    selected: classes.selected,
                }}
                showLabel
                onClick={() => {
                    toRecords("records")
                }}
                label="記録"
                value="records"
                icon={<MenuBookIcon color={loginUser && memberData ? "inherit" : "disabled"} />}
                disabled={!loginUser && !memberData}
            />
            <BottomNavigationAction
                classes={{
                    root: classes.actionItemStyles,
                    selected: classes.selected,
                }}
                showLabel
                onClick={() => {
                    toMemberRecords("memberRecords")
                }}
                label="部員記録"
                value="memberRecords"
                icon={<GroupIcon color={loginUser && memberData ? "inherit" : "disabled"} />}
                disabled={!loginUser && !memberData}
            />
            <BottomNavigationAction
                classes={{
                    root: classes.actionItemStyles,
                    selected: classes.selected,
                }}
                showLabel
                onClick={() => {
                    toAttendance("attendance")
                }}
                label="出欠"
                value="attendance"
                icon={<ContactMailIcon color={loginUser && memberData ? "inherit" : "disabled"} />}
                disabled={!loginUser && !memberData}
            />
            <BottomNavigationAction disabled />
        </BottomNavigation>
    )
}
