import LinearProgress from "@material-ui/core/LinearProgress"
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles"
import React from "react"

const BorderLinearProgress = withStyles({
    root: {
        height: 5,
        backgroundColor: lighten("#00695c", 0.5),
    },
    bar: {
        borderRadius: 10,
        backgroundColor: "#00695c",
    },
})(LinearProgress)

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
}))

export default function LinearIndeterminate(props) {
    const classes = useStyles()
    const { margin } = props
    //////console.log({ margin })
    return (
        <div className={classes.root}>
            <BorderLinearProgress style={margin} />
        </div>
    )
}
