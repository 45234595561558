import { navigate } from "gatsby"
import swal from "sweetalert"

/**
 * swalのerrorを実行し、ログアウトをする関数
 * @param error swalのerror内容
 */

const swalErrorAction = <T>(error: T) => {
    swal(error).then(() => {
        localStorage.clear()
        navigate("/")
    })
}

export default swalErrorAction
