import { makeStyles } from "@material-ui/core/styles"
import MdAdd from "@material-ui/icons/Add"
import AirlineSeatIndividualSuiteIcon from "@material-ui/icons/AirlineSeatIndividualSuite"
import MdClose from "@material-ui/icons/Clear"
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow"
import EditIcon from "@material-ui/icons/Edit"
import React from "react"
import { FloatingMenu, MainButton, ChildButton } from "react-floating-button-menu"

import Theme from "../../../entity/theme/theme"

import AttendanceDialog from "./attendanceDialog"
import ContactDialog from "./contactDialog"
import HitWriteDialog from "./hitWriteDialog"

const useStyles = makeStyles(() => ({
    root: {
        position: "fixed",
        bottom: "5%",
        "@media screen and (min-width:600px) ": {
            bottom: "50px",
        },
        right: "5%",
        zIndex: "99",
        fontFamily: Theme.font.fontFamily,
    },
    fadeLayer: {
        position: "fixed",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100%",
        backgroundColor: "#000000",
        opacity: "0.7",
        visibility: "visible",
        zIndex: "1",
    },

    floatingMenuButton: {
        background: Theme.color.primaryColor,
        color: "white",
    },
    hitWriteButton: {
        background: "wheat",
        color: "white",
    },
    contactButton: {
        background: "pink",
        color: "white",
    },
    attendanceButton: {
        background: "#4caf50",
        color: "white",
    },
    hitWriteTitle: {
        position: "relative",
        bottom: "54px",
        right: "61px",
        zIndex: "100",
        color: "white",
        fontWeight: "bold",
    },
    contactTitle: {
        position: "relative",
        bottom: "121px",
        right: "50px",
        zIndex: "100",
        color: "white",
        fontWeight: "bold",
    },
    attendanceTitle: {
        position: "relative",
        bottom: "131px",
        right: "57px",
        zIndex: "100",
        color: "white",
        fontWeight: "bold",
    },
}))

export default function MenuFloatinButtons(props) {
    const {
        loginUser,
        setContacts,
        setRecords,
        setMonthAllRecords,
        setDistinction,
        memberData,
        setIsLoading,
        title,
        setTitle,
        setAttendanceDay,
        setAllAttendanceDay,
        setExpanded,
        distribution,
        setRangeDate,
    } = props
    const classes = useStyles()
    const [menuOpen, setMenuOpen] = React.useState(false)
    const [openContact, setOpenContact] = React.useState(false)
    const [openHitWrite, setOpenHitWrite] = React.useState(false)
    const [openAttendance, setOpenAttendance] = React.useState(false)

    const openCloseContact = () => {
        setOpenContact(!openContact)
        setMenuOpen(false)
    }

    const openCloseHitWrite = () => {
        setOpenHitWrite(!openHitWrite)
        setMenuOpen(false)
    }

    const openCloseAttendance = () => {
        setOpenAttendance(!openAttendance)
        setMenuOpen(false)
    }

    return (
        <>
            {menuOpen && <div className={classes.fadeLayer}></div>}
            <div className={classes.root}>
                {menuOpen && <div className={classes.attendanceTitle}>欠席遅刻連絡</div>}
                {menuOpen && <div className={classes.hitWriteTitle}>的中書き込み</div>}
                {menuOpen && <div className={classes.contactTitle}>連絡投稿</div>}

                <FloatingMenu
                    slideSpeed={300}
                    direction="up"
                    spacing={8}
                    isOpen={menuOpen}
                    className={classes.root}
                >
                    <MainButton
                        iconResting={<MdAdd style={{ fontSize: 25 }} nativeColor="black" />}
                        iconActive={<MdClose style={{ fontSize: 25 }} nativeColor="white" />}
                        label="ss"
                        className={classes.floatingMenuButton}
                        onClick={() => setMenuOpen(!menuOpen)}
                        size={56}
                    />
                    <ChildButton
                        icon={<DoubleArrowIcon style={{ fontSize: 20 }} nativeColor="black" />}
                        className={classes.hitWriteButton}
                        size={40}
                        label="ss"
                        isOpen={openHitWrite}
                        onClick={openCloseHitWrite}
                    />

                    <ChildButton
                        icon={<EditIcon style={{ fontSize: 20 }} nativeColor="black" />}
                        className={classes.contactButton}
                        size={40}
                        isOpen={openContact}
                        onClick={openCloseContact}
                    />
                    <ChildButton
                        icon={
                            <AirlineSeatIndividualSuiteIcon
                                style={{ fontSize: 20 }}
                                nativeColor="black"
                            />
                        }
                        className={classes.attendanceButton}
                        size={40}
                        isOpen={openContact}
                        onClick={openCloseAttendance}
                    />
                </FloatingMenu>
                <>
                    {loginUser && memberData && openHitWrite && (
                        <HitWriteDialog
                            loginUser={loginUser}
                            setRecords={setRecords}
                            setMonthAllRecords={setMonthAllRecords}
                            setDistinction={setDistinction}
                            memberData={memberData}
                            setIsLoading={setIsLoading}
                            title={title}
                            setTitle={setTitle}
                            setRangeDate={setRangeDate}
                            open={openHitWrite}
                            openClose={openCloseHitWrite}
                        />
                    )}
                    {loginUser && memberData && openContact && (
                        <ContactDialog
                            loginUser={loginUser}
                            setContacts={setContacts}
                            memberData={memberData}
                            open={openContact}
                            openClose={openCloseContact}
                        />
                    )}
                    {loginUser && memberData && openAttendance && (
                        <AttendanceDialog
                            loginUser={loginUser}
                            setAttendanceDay={setAttendanceDay}
                            setAllAttendanceDay={setAllAttendanceDay}
                            setExpanded={setExpanded}
                            distribution={distribution}
                            memberData={memberData}
                            open={openAttendance}
                            openClose={openCloseAttendance}
                        />
                    )}
                </>
            </div>
        </>
    )
}
