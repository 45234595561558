import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import IconButton from "@material-ui/core/IconButton"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import EditIcon from "@material-ui/icons/Edit"
import { navigate } from "gatsby"
import React from "react"
import fontStyle from "src/entity/theme/fontStyle"
import swal from "sweetalert"

import swalErrorAction from "../../../entity/alert/swalErrorAction"
import { changeError, networkError } from "../../../entity/alert/swalErrorList"
import Theme from "../../../entity/theme/theme"
import { userRepository } from "../../../repository/action"
import LinearProgress from "../../atoms/linearProgress"
const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 250,
        fontFamily: Theme.font.fontFamily,
    },
    dialogTitle: {
        padding: "16px 24px 0px  24px",
    },
}))

export default function NameEdit(props) {
    const { loginUser, setLoginUser, setMemberData } = props
    const classes = useStyles()
    const [linearAnimation, setLinearAnimation] = React.useState(false)
    const [openEdit, setOpenEdit] = React.useState(false)
    const [newName, setNewName] = React.useState("")

    const onClose = () => {
        setOpenEdit(false)
        setNewName(loginUser.name)
    }
    const handleChange = (event) => {
        setNewName(event.target.value)
    }

    return (
        <>
            {loginUser && (
                <>
                    <IconButton
                        color="primary"
                        onClick={() => {
                            setOpenEdit(true)
                            setNewName(loginUser.name)
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                    <Dialog
                        disableBackdropClick
                        disableEscapeKeyDown
                        open={openEdit}
                        onClose={onClose}
                    >
                        {linearAnimation && <LinearProgress />}
                        <DialogTitle className={classes.dialogTitle}>
                            <div style={fontStyle("bold")}>名前変更</div>
                            <DialogContentText>*1〜10文字以内</DialogContentText>
                        </DialogTitle>
                        <DialogContent>
                            <form className={classes.container}>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        rows={1}
                                        defaultValue={loginUser.name}
                                        style={{ marginTop: "8px" }}
                                        InputLabelProps={{ style: fontStyle("500") }}
                                        className={classes.textField}
                                        onChange={handleChange}
                                        margin="normal"
                                    />
                                </FormControl>
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="primary">
                                <div style={fontStyle("bold")}> 閉じる</div>
                            </Button>
                            <Button
                                disabled={
                                    newName.length < 1 || newName.length > 10 || linearAnimation
                                }
                                onClick={() => {
                                    setLinearAnimation(true)
                                    userRepository
                                        .nameChange(loginUser, newName)
                                        .then(() => {
                                            userRepository
                                                .sessionLogin(loginUser.email, loginUser.password)
                                                .then((data) => {
                                                    setLinearAnimation(false)
                                                    swal({
                                                        title: "名前を変更しました",
                                                        icon: "success",
                                                        buttons: false,
                                                        timer: 1300,
                                                    })

                                                    setLoginUser(data.user_info)
                                                    setMemberData(data.memberData)
                                                    onClose()
                                                })
                                                .catch(() => {
                                                    swalErrorAction(networkError)
                                                })
                                        })
                                        .catch(() => {
                                            swal(changeError).then(() => {
                                                onClose()
                                                localStorage.clear()
                                                navigate("/")
                                            })
                                        })
                                }}
                                color="primary"
                            >
                                <div style={fontStyle("bold")}>変更</div>
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    )
}
