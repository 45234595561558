import "date-fns"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar"
import React from "react"

import fontStyle from "../../../entity/theme/fontStyle"
import Theme from "../../../entity/theme/theme"
import DateChange from "../../atoms/dateChange"

import MailSwitch from "./mailSwitch"
import UploadPhoto from "./uploadPhoto"

const useStyles = makeStyles((theme) => ({
    container: {
        justifyContent: "left",
        marginTop: 32,
        display: "flex",
        flexWrap: "wrap",
        margin: "30px",
        fontFamily: Theme.font.fontFamily,
        textAlign: "left",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 250,
        fontFamily: Theme.font.fontFamily,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    switch: {
        marginTop: "35px",
        marginBottom: "-30px",
        position: "relative",
        left: "3px",
        bottom: "24px",
    },
    title: {
        fontWeight: "bold",
        display: "inline-block",
        position: "relative",
        top: "5px",
        fontFamily: Theme.font.fontFamily,
    },
    titlePosition: {
        fontSize: "20px",
        display: "flex",
        position: "relative",
        left: "20px",
        top: "20px",
    },
}))

const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "green",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "green",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "red",
            },
            "&:hover fieldset": {
                borderColor: "yellow",
            },
            "&.Mui-focused fieldset": {
                borderColor: "green",
            },
        },
    },
})(TextField)

const IconColors = makeStyles({
    root: {
        color: "#4caf50",
    },
})

export default function ContactForm(props) {
    const {
        setBool,
        bool,
        loginUser,
        contact,
        setContact,
        mailState,
        setMailState,
        image,
        setImage,
    } = props
    const classes = useStyles()
    const iconClasses = IconColors()
    const [emailTitle] = React.useState("部員に一斉送信")

    let date = DateChange(new Date().toString())
    let getHours = new Date().getHours()
    let getMinutes = new Date().getMinutes()
    if (getHours < 10) {
        getHours = "0" + getHours
    }
    if (getMinutes < 10) {
        getMinutes = "0" + getMinutes
    }
    let changeDate = date + getHours + ":" + getMinutes

    const handleChange = (name, i) => (event) => {
        if (name == "item") {
            let value = event.target.value
            let item = value.split("&")
            setContact({ ...contact, [name]: item[0], itemColor: item[1] })
        } else {
            setContact({ ...contact, [name]: event.target.value })
        }
        if (event.target.value.length > 0) {
            let disabled = [...bool]
            disabled[i] = false
            setBool([...disabled])
        } else {
            let disabled = [...bool]
            disabled[i] = true
            setBool([...disabled])
        }
    }

    React.useEffect(() => {
        setContact({
            ...contact,
            contributor: loginUser.name,
            post_date: changeDate,
            contributor_id: loginUser.id,
            contributor_gender: loginUser.gender,
        })
    }, [])

    return (
        <>
            <div className={classes.titlePosition}>
                <span>
                    <PermContactCalendarIcon fontSize="large" className={iconClasses.root} />
                </span>
                <span className={classes.title}>連絡投稿フォーム</span>
            </div>

            <form className={classes.container} noValidate autoComplete="off">
                <FormControl className={classes.formControl} required>
                    <InputLabel>
                        <span style={fontStyle("500")}>項目</span>
                    </InputLabel>
                    <Select
                        value={contact.item + "&" + contact.itemColor}
                        onChange={handleChange("item")}
                        label="項目"
                        autoWidth
                    >
                        <MenuItem value={"重要連絡&red"}>
                            <span style={fontStyle("500")}>重要連絡</span>
                        </MenuItem>
                        <MenuItem value={"急ぎ連絡&cornflowerblue"}>
                            <span style={fontStyle("500")}>急ぎ連絡</span>
                        </MenuItem>
                        <MenuItem value={"係からの連絡&forestgreen"}>
                            <span style={fontStyle("500")}>係からの連絡</span>
                        </MenuItem>
                        <MenuItem value={"予定連絡&hotpink"}>
                            <span style={fontStyle("500")}>予定連絡</span>
                        </MenuItem>
                        <MenuItem value={"全体連絡&blueviolet"}>
                            <span style={fontStyle("500")}>全体連絡</span>
                        </MenuItem>
                        <MenuItem value={"三年生へ&gold"}>
                            <span style={fontStyle("500")}>三年生へ</span>
                        </MenuItem>
                        <MenuItem value={"二年生へ&silver"}>
                            <span style={fontStyle("500")}>二年生へ</span>
                        </MenuItem>
                        <MenuItem value={"一年生へ&burlywood"}>
                            <span style={fontStyle("500")}>一年生へ</span>
                        </MenuItem>
                    </Select>
                    <FormHelperText>
                        <span style={fontStyle("500")}>連絡の種類を選択してください</span>
                    </FormHelperText>
                </FormControl>
                <CssTextField
                    id="standard-textarea3"
                    label="タイトル"
                    placeholder="部会について"
                    multiline
                    rows={1}
                    rowsMax={3}
                    style={{ marginTop: "8px" }}
                    InputLabelProps={{ style: fontStyle("500") }}
                    className={classes.textField}
                    onChange={handleChange("title", 0)}
                    margin="normal"
                    //required
                />

                <CssTextField
                    id="standard-textarea5"
                    label="内容"
                    placeholder="連絡事項など"
                    multiline
                    rows={1}
                    rowsMax={5}
                    InputLabelProps={{ style: fontStyle("500") }}
                    style={{ marginTop: "8px" }}
                    className={classes.textField}
                    onChange={handleChange("description", 1)}
                    margin="normal"
                    required
                    //accept-charset
                />
                <UploadPhoto image={image} setImage={setImage} />

                <div className={classes.switch}>
                    <MailSwitch
                        setMailState={setMailState}
                        mailState={mailState}
                        emailTitle={emailTitle}
                    />
                    <FormHelperText>
                        <span style={fontStyle("500")}>
                            HitRateに登録されている現役部員のメールアドレスに内容を送信する
                        </span>
                    </FormHelperText>
                </div>
            </form>
        </>
    )
}
