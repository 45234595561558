import AppBar from "@material-ui/core/AppBar"
import Badge from "@material-ui/core/Badge"
import CssBaseline from "@material-ui/core/CssBaseline"
import Dialog from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/IconButton"
import Slide from "@material-ui/core/Slide"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import GroupAddIcon from "@material-ui/icons/GroupAdd"
import MoreIcon from "@material-ui/icons/MoreVert"
import clsx from "clsx"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import fontStyle from "src/entity/theme/fontStyle"

import Theme from "../../../entity/theme/theme"
import WaitCircle from "../../atoms/waitCircle"

import MenuContent from "./menuContent"
const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        overflow: "hidden",
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: Theme.color.primaryColor,
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color: "#fff",
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
        backgroundSize: "cover",
        minHeight: "58px!imoprtant",
    },
    content: {
        flexGrow: 1,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    loginUser: {
        position: "fixed",
        right: "2px",
        display: "inherit",
    },
    groupIcon: {
        position: "fixed",
        right: "47px",
        display: "inherit",
    },
    iconButton: {
        position: "fixed",
        zIndex: 100,
        color: "white",
    },
    waitCircle: {
        position: "fixed",
        left: "43%",
        top: "50%",
    },
    groupMiniGame1: {
        color: "red",
    },
    customBadge: {
        fontWeight: "bold",
    },
}))

function HideOnScroll(props) {
    const { children, window } = props

    const trigger = useScrollTrigger({ target: window ? window() : undefined })

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    )
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
}
export default function TopAppBar(props) {
    const { loginUser, setLoginUser, memberData, setMemberData, title, notificationCount } = props
    const classes = useStyles()
    const [open] = React.useState(false)
    const [nameFlag] = React.useState(true)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [menuContentOpend, setMenuContentOpend] = React.useState([false, false, false, false])

    const opened = Boolean(anchorEl)

    const toAdmin = () => {
        let pathname = window.location.pathname.split("/")
        if (!(pathname[1] == "admin")) {
            navigate("/admin", { state: { loginUser, memberData } })
        }
    }

    const handleMenu = (event) => {
        setMenuContentOpend([false, false, false, false])
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <HideOnScroll {...props}>
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar
                        style={{
                            minHeight: "52px",
                        }}
                    >
                        <Typography letiant="h6" noWrap style={fontStyle("600")}>
                            {title}
                        </Typography>
                        {nameFlag && (
                            <>
                                {loginUser && loginUser.type == "leaders" && (
                                    <IconButton
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={toAdmin}
                                        color="inherit"
                                        className={classes.groupIcon}
                                    >
                                        <Badge
                                            color="secondary"
                                            badgeContent={notificationCount}
                                            style={{ margin: "-1px", fontWeight: "bold" }}
                                            classes={{ badge: classes.customBadge }}
                                        >
                                            <GroupAddIcon />
                                        </Badge>
                                    </IconButton>
                                )}
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                    className={classes.loginUser}
                                    disabled={!loginUser && !memberData}
                                >
                                    <MoreIcon />
                                </IconButton>
                                <Dialog open={opened} onClose={handleClose}>
                                    <MenuContent
                                        handleClose={handleClose}
                                        anchorEl={anchorEl}
                                        memberData={memberData}
                                        setMemberData={setMemberData}
                                        loginUser={loginUser}
                                        setLoginUser={setLoginUser}
                                        menuContentOpend={menuContentOpend}
                                        setMenuContentOpend={setMenuContentOpend}
                                    />
                                    {menuContentOpend[0] && (
                                        <div className={classes.waitCircle}>
                                            <WaitCircle width={"60px"} height={"60px"} />
                                        </div>
                                    )}
                                    {menuContentOpend[1] && (
                                        <div className={classes.waitCircle}>
                                            <WaitCircle width={"60px"} height={"60px"} />
                                        </div>
                                    )}
                                    {menuContentOpend[2] && (
                                        <div className={classes.waitCircle}>
                                            <WaitCircle width={"60px"} height={"60px"} />
                                        </div>
                                    )}
                                    {menuContentOpend[3] && (
                                        <div className={classes.waitCircle}>
                                            <WaitCircle width={"60px"} height={"60px"} />
                                        </div>
                                    )}
                                </Dialog>
                            </>
                        )}
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
        </div>
    )
}
