import "date-fns"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import enLocale from "date-fns/locale/en-US"
import jaLocale from "date-fns/locale/ja"
import React from "react"
import fontStyle from "src/entity/theme/fontStyle"

import JaLocalizedUtils from "@/entity/service/jaLocalizedUtils"

const localeMap = {
    ja: jaLocale,
    en: enLocale,
}

const localeFormatMap = {
    ja: "yyyy年M月d日",
    en: "d MMMM, yyyy",
}

const localeCancelLabelMap = {
    ja: "キャンセル",
    en: "cancel",
}

const useStyles = makeStyles((theme) => ({
    container: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}))

export default function DatePicker(props) {
    const classes = useStyles()
    const { handleDateChange, selectedDate, classification, loginUser, disableFlag } = props
    const [locale] = React.useState("ja")

    let minDate
    if (classification == "通常練習" && loginUser.type !== "administrator") {
        minDate = new Date().setDate(1)
    } else if (classification == "強化練習" && loginUser.type !== "administrator") {
        // minDate = new Date().setDate(new Date().getDate() - 7);
        minDate = new Date().setDate(1)
    } else if (classification == "自主練習" && loginUser.type !== "administrator") {
        // minDate = new Date().setDate(new Date().getDate() - 1);
        minDate = new Date().setDate(1)
    }
    ////////console.log({ loginUser })
    return (
        <div className={classes.container}>
            <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={localeMap[locale]}>
                <Grid container justify="center" style={{ width: "178px" }}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        style={{ marginTop: "0px" }}
                        label={<div>練習日</div>}
                        disableFuture={classification !== "attendance" && true}
                        disablePast={classification == "attendance" && true}
                        minDate={classification !== "attendance" && minDate}
                        format={localeFormatMap[locale]}
                        cancelLabel={localeCancelLabelMap[locale]}
                        value={selectedDate}
                        onChange={handleDateChange}
                        disabled={disableFlag}
                        KeyboardButtonProps={{
                            "aria-label": "change date",
                        }}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
            {classification && classification !== "attendance" && !disableFlag && (
                <div style={fontStyle("500")}>
                    <span style={{ color: "grey" }}>＊今月のみ記録できます</span>
                </div>
            )}
        </div>
    )
}
