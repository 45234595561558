import DateFnsUtils from "@date-io/date-fns"
import { format } from "date-fns"

class JaLocalizedUtils extends DateFnsUtils {
    // ヘッダ部分のテキストを取得するメソッド
    getDatePickerHeaderText(date: Date) {
        return format(date, "M月d日(E)", { locale: this.locale })
    }
}

export default JaLocalizedUtils
