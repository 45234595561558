import "date-fns"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import AirlineSeatIndividualSuiteIcon from "@material-ui/icons/AirlineSeatIndividualSuite"
import React from "react"
import fontStyle from "src/entity/theme/fontStyle"

import Theme from "../../../entity/theme/theme"
import DateChange from "../../atoms/dateChange"

import DatePicker from "./datePicker"
import MailSwitch from "./mailSwitch"

const useStyles = makeStyles((theme) => ({
    container: {
        justifyContent: "left",
        marginTop: 32,
        display: "flex",
        flexWrap: "wrap",
        margin: "30px",
        fontFamily: Theme.font.fontFamily,
        //textAlign: "left",
        overflow: "hidden",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 250,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    switch: {
        marginTop: "35px",
        marginBottom: "-30px",
        position: "relative",
        left: "3px",
        bottom: "24px",
    },
    title: {
        fontWeight: "bold",
        display: "inline-block",
        position: "relative",
        top: "5px",
        fontFamily: Theme.font.fontFamily,
    },
    titlePosition: {
        fontSize: "20px",
        display: "flex",
        position: "relative",
        left: "20px",
        top: "20px",
    },
}))

const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "green",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "green",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "red",
            },
            "&:hover fieldset": {
                borderColor: "yellow",
            },
            "&.Mui-focused fieldset": {
                borderColor: "green",
            },
        },
    },
})(TextField)

const IconColors = makeStyles({
    root: {
        color: "#4caf50",
    },
})

export default function AttendanceForm(props) {
    const {
        setBool,
        bool,
        loginUser,
        memberData,
        attendance,
        setAttendance,
        mailState,
        setMailState,
        selectedDate,
        setSelectedDate,
    } = props
    const classes = useStyles()
    const iconClasses = IconColors()
    const [emailTitle] = React.useState("幹部に送信")

    //postDate
    let date = DateChange(new Date().toString())
    let getHours = new Date().getHours()
    let getMinutes = new Date().getMinutes()
    if (getHours < 10) {
        getHours = "0" + getHours
    }
    if (getMinutes < 10) {
        getMinutes = "0" + getMinutes
    }
    let changeDate = date + getHours + ":" + getMinutes

    const handleChange = (name, i) => (event) => {
        setAttendance({ ...attendance, [name]: event.target.value })

        if (event.target.value.length > 0) {
            let disabled = [...bool]
            disabled[i] = false
            setBool([...disabled])
        } else {
            let disabled = [...bool]
            disabled[i] = true
            setBool([...disabled])
        }
    }

    const handleDateChange = (date) => {
        //console.log(date)
        setSelectedDate(date)
        if (date === null || String(date) == "Invalid Date") {
            let disabled = [...bool]
            disabled[0] = true
            setBool([...disabled])
        } else {
            let disabled = [...bool]
            disabled[0] = false
            setBool([...disabled])
        }
    }

    React.useEffect(() => {
        setAttendance({
            ...attendance,
            contributor: loginUser.name,
            post_date: changeDate,
            contributor_id: loginUser.id,
            contributor_gender: loginUser.gender,
        })
    }, [])

    // ////console.log({ memberData })
    return (
        <>
            <div className={classes.titlePosition}>
                <span>
                    <AirlineSeatIndividualSuiteIcon fontSize="large" className={iconClasses.root} />
                </span>
                <span className={classes.title}>出欠連絡フォーム</span>
            </div>

            <form className={classes.container} noValidate autoComplete="off">
                <DatePicker
                    handleDateChange={handleDateChange}
                    selectedDate={selectedDate}
                    loginUser={loginUser}
                    classification={"attendance"}
                />
                <div className={classes.mediaScreen}>
                    <FormControl className={classes.formControl}>
                        <InputLabel>
                            <span style={fontStyle("500")}>選択</span>
                        </InputLabel>
                        <Select
                            value={attendance.select}
                            onChange={handleChange("select")}
                            label="選択"
                            autoWidth
                        >
                            <MenuItem value={"早退"}>
                                <span style={fontStyle("500")}>早退</span>
                            </MenuItem>
                            <MenuItem value={"遅刻"}>
                                <span style={fontStyle("500")}>遅刻</span>
                            </MenuItem>
                            <MenuItem value={"欠席"}>
                                <span style={fontStyle("500")}>欠席</span>
                            </MenuItem>
                        </Select>
                        <FormHelperText>
                            <span style={fontStyle("500")}>
                                早退、遅刻、欠席から選択してください
                            </span>
                        </FormHelperText>
                    </FormControl>
                </div>
                <CssTextField
                    id="standard-textarea5"
                    label="本文"
                    placeholder="腹痛で休みます"
                    multiline
                    rows={1}
                    rowsMax={6}
                    InputLabelProps={{ style: fontStyle("500") }}
                    style={{ marginTop: "8px" }}
                    className={classes.textField}
                    onChange={handleChange("sentence", 1)}
                    margin="normal"
                    required
                />
                <div className={classes.switch}>
                    <MailSwitch
                        setMailState={setMailState}
                        mailState={mailState}
                        emailTitle={emailTitle}
                    />
                    <FormHelperText style={{ marginBottom: "4px" }}>
                        <span style={fontStyle("500")}>
                            幹部部員(
                            {memberData.map((value, i, array) => {
                                //  ////console.log(value.permission)
                                let index = array.findIndex((value) => {
                                    return (
                                        value.permission == "2" &&
                                        value.type == "leaders" &&
                                        loginUser.name !== value.name
                                    )
                                })
                                //////console.log(index)
                                if (loginUser.name !== value.name) {
                                    if (
                                        value.permission == "2" &&
                                        value.type == "leaders" &&
                                        index == i
                                    ) {
                                        return value.name
                                    } else if (
                                        value.permission === "2" &&
                                        value.type == "leaders"
                                    ) {
                                        return "," + value.name
                                    }
                                }
                            })}
                            )のメールアドレスに内容を送信します。
                        </span>
                    </FormHelperText>
                </div>
            </form>
        </>
    )
}
