import Avatar from "@material-ui/core/Avatar"
import Backdrop from "@material-ui/core/Backdrop"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Fade from "@material-ui/core/Fade"
import IconButton from "@material-ui/core/IconButton"
import Popper from "@material-ui/core/Popper"
import { makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import ContactSupportIcon from "@material-ui/icons/ContactSupport"
import EditIcon from "@material-ui/icons/Edit"
import moment from "moment"
import React from "react"
import fontStyle from "src/entity/theme/fontStyle"
import hitAll from "src/images/badgePic/hitAll.png"
import hitRate from "src/images/badgePic/hitRate.png"
import month000 from "src/images/badgePic/month000.png"
import month1 from "src/images/badgePic/month1.png"
import month2 from "src/images/badgePic/month2.png"
import month3 from "src/images/badgePic/month3.png"
import month4 from "src/images/badgePic/month4.png"
import month5 from "src/images/badgePic/month5.png"
import questions from "src/images/badgePic/questions.png"
import selfPractice from "src/images/badgePic/selfPractice.png"
import rank0 from "src/images/rankPic/rank0.png"
import rank10 from "src/images/rankPic/rank10.png"
import rank100 from "src/images/rankPic/rank100.png"
import rank15 from "src/images/rankPic/rank15.png"
import rank20 from "src/images/rankPic/rank20.png"
import rank25 from "src/images/rankPic/rank25.png"
import rank30 from "src/images/rankPic/rank30.png"
import rank35 from "src/images/rankPic/rank35.png"
import rank40 from "src/images/rankPic/rank40.png"
import rank45 from "src/images/rankPic/rank45.png"
import rank5 from "src/images/rankPic/rank5.png"
import rank50 from "src/images/rankPic/rank50.png"
import rank55 from "src/images/rankPic/rank55.png"
import rank60 from "src/images/rankPic/rank60.png"
import rank65 from "src/images/rankPic/rank65.png"
import rank70 from "src/images/rankPic/rank70.png"
import rank75 from "src/images/rankPic/rank75.png"
import rank80 from "src/images/rankPic/rank80.png"
import rank85 from "src/images/rankPic/rank85.png"
import rank90 from "src/images/rankPic/rank90.png"
import rank95 from "src/images/rankPic/rank95.png"
import { window } from "ssr-window"

import swalErrorAction from "../../../entity/alert/swalErrorAction"
import { networkError } from "../../../entity/alert/swalErrorList"
import Theme from "../../../entity/theme/theme"
import { userRepository, recordsRepository } from "../../../repository/action"
import PictureAnimation from "../../atoms/pictureAnimation"

import NameEdit from "./nameEdit"
import RankDetails from "./rankDetails"

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "left",
        fontSize: "15px",
        padding: "3px",
        fontWeight: "bold",
        color: "white",
        background: Theme.color.secondaryColor,
        overflow: "hidden",
        zIndex: "1000",
    },
    userinfo: {
        textAlign: "center",
        fontSize: "20px",
        fontWeight: "bold",
        margin: "8px 0px",
        borderRadius: "2px",
        textShadow: "1px 2px 3px #808080",
        display: "flex",
        justifyContent: "center",
        position: "relative",
    },
    abyss: {
        borderRadius: "3px",
        border: "solid 1px #808080",
        width: "100%",
        overflow: "scroll",
        height: window.innerHeight - 140,
        maxHeight: "690px",
        background: Theme.color.primaryColor,
        padding: "0.5em",
        //overflow: "auto",
        fontFamily: Theme.font.fontFamily,
    },
    itemTitle: {
        textAlign: "center",
        padding: "3px",
        textShadow: "1px 2px 3px #808080",
        borderRadius: "2px",
        background: Theme.color.secondaryColor,
    },
    buttonPosition: {
        position: "abosolute",
        bottom: "0px",
        right: "0px",
        color: "white",
    },
    cardInset: {
        boxShadow: "inset 0px 1px 2px 1px #ccc",
        width: "50%",
        textAlign: "center",
    },
    cardInset2: {
        boxShadow: "inset 0px 1px 2px 1px #ccc",
        width: "100%",
        textAlign: "center",
        padding: "0px",
    },
    rank: {
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
    },
    typography: {
        padding: theme.spacing(2),
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
    },
}))

function compare(a, b) {
    const genreA = Number(a)
    const genreB = Number(b)

    let comparison = 0
    if (genreA > genreB) {
        comparison = 1
    } else if (genreA < genreB) {
        comparison = -1
    }
    return comparison
}
function compare2(a, b) {
    if (a !== null && b !== null) {
        const genreA = Number(a.hit_rate)
        const genreB = Number(b.hit_rate)

        let comparison = 0
        if (genreA > genreB) {
            comparison = 1
        } else if (genreA < genreB) {
            comparison = -1
        }
        return comparison
    }
}

//月の的中率を出す（先月）
function monthHitRate(year, month, person) {
    if (person.records.length > 0) {
        const monthData = {
            hit_arrow: 0,
            all_arrow: 0,
        }
        person.records.map((data) => {
            if (year == data.date.split("/")[0] && month == data.date.split("/")[1]) {
                monthData.hit_arrow += data.hit_arrow
                monthData.all_arrow += data.all_arrow
            }
        })
        monthData.hit_rate = (monthData.hit_arrow / monthData.all_arrow) * 100
        if (monthData.hit_arrow == 0 && monthData.all_arrow == 0) {
            return null
        } else {
            return Math.round(monthData.hit_rate)
        }
    } else {
        return null
    }
}

//create月の翌月から一番当たった的中率をだす
function monthBestHitRate(year, month, person) {
    if (person.records.length > 0) {
        let records = []
        let thisYear = Number(new Date().getFullYear())
        let thisMonth = 12
        for (let yearNum = year; yearNum <= thisYear; yearNum++) {
            if (yearNum == thisYear) {
                thisMonth = Number(new Date().getMonth())
            }
            for (let monthNum = month; monthNum <= thisMonth; monthNum++) {
                let monthData = {
                    hit_arrow: 0,
                    all_arrow: 0,
                    year: 0,
                    month: 0,
                }
                person.records.map((data) => {
                    if (yearNum == data.date.split("/")[0] && monthNum == data.date.split("/")[1]) {
                        monthData.hit_arrow += data.hit_arrow
                        monthData.all_arrow += data.all_arrow
                        monthData.year = data.date.split("/")[0]
                        monthData.month = data.date.split("/")[1]
                    }
                })
                monthData.hit_rate = (monthData.hit_arrow / monthData.all_arrow) * 100
                records.push({
                    hit_rate: Math.round(monthData.hit_rate),
                    year: monthData.year,
                    month: monthData.month,
                })
            }
            month = 1
        }
        const badNaNRecords = records.filter((item) => {
            return item.hit_rate !== null && !isNaN(Number(item.hit_rate))
        })
        if (badNaNRecords.length == 1) {
            return badNaNRecords[0]
        } else if (badNaNRecords.length > 1) {
            badNaNRecords.sort(compare2)
            badNaNRecords.reverse()
            return badNaNRecords[0]
        } else {
            return null
        }
    } else {
        return null
    }
}

function allHitRate(records) {
    const allData = {
        hit_arrow: 0,
        all_arrow: 0,
    }
    records.map((data) => {
        allData.hit_arrow += data.hit_arrow
        allData.all_arrow += data.all_arrow
    })
    allData.hit_rate = Math.round((allData.hit_arrow / allData.all_arrow) * 100)
    if (allData.hit_arrow == 0 && allData.all_arrow == 0) {
        allData.hit_rate = 0
        return allData
    } else {
        return allData
    }
}

export default function Profile(props) {
    const {
        loginUser,
        setLoginUser,
        fetchUser,
        memberData,
        setMemberData,
        onClose,
        profileOpenFlag,
    } = props
    const classes = useStyles()
    const [allRecords, setAllRecords] = React.useState({ records: [] })
    const [memberRecords, setMemberRecords] = React.useState()
    const [rankDetailsOpen, setRankDetailsOpen] = React.useState(false)
    const [rankPic] = React.useState([
        rank0,
        rank5,
        rank10,
        rank15,
        rank20,
        rank25,
        rank30,
        rank35,
        rank40,
        rank45,
        rank50,
        rank55,
        rank60,
        rank65,
        rank70,
        rank75,
        rank80,
        rank85,
        rank90,
        rank95,
        rank100,
    ])
    const [bestMonth, setBestMonth] = React.useState()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [placement, setPlacement] = React.useState()
    const [open, setOpen] = React.useState(false)

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget)
        setOpen(!open)
        setPlacement(newPlacement)
    }

    React.useEffect(() => {
        recordsRepository
            .allRecordsFetch(fetchUser)
            .then((data) => {
                setAllRecords(data)
                let bestRate = monthBestHitRate(
                    fetchUser.create_date.split("/")[0],
                    fetchUser.create_date.split("/")[1] - 1,
                    data
                )
                if (bestRate == null) {
                    bestRate = { hit_rate: 0, all_arrow: 0, hit_arrow: 0 }
                }
                setBestMonth(bestRate)
                userRepository.allMemberDataFetch(fetchUser, memberData).then((data) => {
                    let allowMemberData = memberData.filter((value) => {
                        if (value.type !== "ob" && value.permission === "2") {
                            return value
                        }
                    })
                    const result = data.map((value, index) => {
                        let arrow = {
                            records: value,
                            name: allowMemberData[index].name,
                            type: allowMemberData[index].type,
                        }
                        return arrow
                    })
                    const filterResult = result.filter((v) => v)
                    setMemberRecords(filterResult)
                })
            })
            .catch(() => {
                swalErrorAction(networkError)
            })
    }, [])

    return (
        <>
            {
                loginUser && fetchUser && allRecords && memberRecords && bestMonth && (
                    <div className={classes.root}>
                        <Backdrop
                            open={open}
                            onClick={() => {
                                setOpen(false)
                            }}
                            style={{ zIndex: 300, opacity: "-1" }}
                        />
                        <Popper
                            open={open}
                            anchorEl={anchorEl}
                            placement={placement}
                            transition
                            style={{ zIndex: 200000, width: "255px" }}
                        >
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                    <Card className={classes.typography}>
                                        <span style={{ fontWeight: "bold" }}>
                                            通常練習の記録のみ反映されます
                                        </span>
                                    </Card>
                                </Fade>
                            )}
                        </Popper>
                        <RankDetails
                            loginUser={loginUser}
                            memberData={memberData}
                            memberRecords={memberRecords}
                            allRecords={allRecords}
                            setRankDetailsOpen={setRankDetailsOpen}
                            rankDetailsOpen={rankDetailsOpen}
                        />
                        <div className={classes.userinfo}>
                            <div style={fontStyle("bold")}>プロフィール</div>
                            <div style={{ right: "0px", top: "-11px", position: "absolute" }}>
                                <IconButton color="secondary" onClick={onClose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div className={classes.abyss}>
                            <div>
                                <div style={{ display: "flex", margin: "4px" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "start",
                                            flexDirection: "row",
                                            marginLeft: "-4px",
                                        }}
                                    >
                                        <div>
                                            {fetchUser.gender == "woman" ? (
                                                <Avatar
                                                    style={{
                                                        background: Theme.color.womanIconColor,
                                                        paddingLeft: "3px",
                                                    }}
                                                >
                                                    {fetchUser.name.slice(0, 1)}
                                                </Avatar>
                                            ) : (
                                                <Avatar
                                                    style={{
                                                        background: Theme.color.manIconColor,
                                                        paddingLeft: "3px",
                                                    }}
                                                >
                                                    {fetchUser.name.slice(0, 1)}
                                                </Avatar>
                                            )}
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                flexDirection: "row",
                                                fontSize: "18px",
                                                width: "140px",
                                                marginLeft: "4px",
                                            }}
                                        >
                                            <div>{fetchUser.name}</div>
                                            {profileOpenFlag ? (
                                                <>
                                                    {loginUser && (
                                                        <NameEdit
                                                            loginUser={loginUser}
                                                            setLoginUser={setLoginUser}
                                                            setMemberData={setMemberData}
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <IconButton disabled style={{ opacity: "0" }}>
                                                    <EditIcon />
                                                </IconButton>
                                            )}
                                        </div>
                                    </div>
                                    {(() => {
                                        let year = moment().format("YYYY")
                                        let month = moment().format("M")
                                        let thisMonth = monthHitRate(year, month, allRecords)
                                        let ranking
                                        let num = 0
                                        rankPic.forEach((value) => {
                                            if (num <= thisMonth && thisMonth < num + 5) {
                                                ranking = (
                                                    <img
                                                        src={value}
                                                        style={{
                                                            width: "74%",
                                                            maxWidth: "90px",
                                                            margin: "7px auto",
                                                        }}
                                                        alt={value}
                                                        onClick={() => {
                                                            setRankDetailsOpen(true)
                                                        }}
                                                    />
                                                )
                                            }
                                            num = num + 5
                                        })

                                        return (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    marginLeft: "auto",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <div
                                                    style={{ fontSize: "16px", marginLeft: "15px" }}
                                                >
                                                    {" "}
                                                    今月
                                                    <IconButton
                                                        style={{
                                                            color: "#fff",
                                                            top: "-9px",
                                                            padding: "0px",
                                                        }}
                                                        onClick={handleClick("bottom-start", 1)}
                                                    >
                                                        <ContactSupportIcon
                                                            style={{ fontSize: "smaller" }}
                                                        />
                                                    </IconButton>
                                                </div>
                                                {thisMonth == null ? (
                                                    <>
                                                        <img
                                                            src={questions}
                                                            style={{ margin: "7px" }}
                                                        />
                                                        <div>データなし</div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div>{ranking}</div>
                                                        <div style={{ fontSize: "16px" }}>
                                                            {thisMonth}%
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )
                                    })()}
                                </div>

                                <div style={{ textAlign: "left", margin: "-20px 0px 0px 4px" }}>
                                    役職：
                                    {fetchUser.type == "member" && <>通常部員</>}
                                    {fetchUser.type == "leaders" && <>幹部部員</>}
                                    {fetchUser.type == "ob" && <>OB</>}
                                    {fetchUser.type == "administrator" && <>管理者</>}
                                    <div>所属：{loginUser.team}</div>
                                </div>

                                <div className={classes.itemTitle}>通常練習 月間的中率</div>
                                <Card style={{ display: "flex", flexWrap: "wrap" }}>
                                    <CardContent
                                        className={classes.cardInset}
                                        style={{ paddingBottom: "16px", width: "100%" }}
                                    >
                                        <div>ベスト月</div>
                                        {(() => {
                                            let ranking
                                            let num = 0
                                            rankPic.forEach((value) => {
                                                if (
                                                    num <= bestMonth.hit_rate &&
                                                    bestMonth.hit_rate < num + 5
                                                ) {
                                                    ranking = (
                                                        <img
                                                            src={value}
                                                            style={{
                                                                width: "26%",
                                                                margin: "7px",
                                                                maxWidth: "90px",
                                                            }}
                                                            alt={value}
                                                            onClick={() => {
                                                                setRankDetailsOpen(true)
                                                            }}
                                                        />
                                                    )
                                                }
                                                num = num + 5
                                            })
                                            return (
                                                <>
                                                    {bestMonth.all_arrow == 0 ? (
                                                        <>
                                                            <img
                                                                src={questions}
                                                                style={{ margin: "7px" }}
                                                            />
                                                            <div>データなし</div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <PictureAnimation>
                                                                {ranking}
                                                            </PictureAnimation>
                                                            <div>
                                                                {" "}
                                                                {bestMonth.hit_rate}%,
                                                                {bestMonth.year}年{bestMonth.month}
                                                                月{" "}
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )
                                        })()}
                                    </CardContent>
                                </Card>
                                <Card style={{ display: "flex" }}>
                                    <CardContent
                                        className={classes.cardInset}
                                        style={{ paddingBottom: "16px", width: "100%" }}
                                    >
                                        <div>先々月</div>
                                        {(() => {
                                            let year = moment(moment().subtract(2, "M")).format(
                                                "YYYY"
                                            )
                                            let month = moment(moment().subtract(2, "M")).format(
                                                "M"
                                            )
                                            let lastMonth = monthHitRate(year, month, allRecords)
                                            let rank
                                            let ranking
                                            if (lastMonth !== null) {
                                                let memberLastMonth = memberRecords.map((data) => {
                                                    return monthHitRate(year, month, data)
                                                })
                                                memberLastMonth.sort(compare)
                                                memberLastMonth.reverse()
                                                rank = memberLastMonth.findIndex((v) => {
                                                    return v === lastMonth
                                                })
                                                let num = 0
                                                rankPic.forEach((value) => {
                                                    if (num <= lastMonth && lastMonth < num + 5) {
                                                        ranking = (
                                                            <img
                                                                src={value}
                                                                style={{
                                                                    width: "65%",
                                                                    maxWidth: "90px",
                                                                }}
                                                                alt={value}
                                                                onClick={() => {
                                                                    setRankDetailsOpen(true)
                                                                }}
                                                            />
                                                        )
                                                    }
                                                    num = num + 5
                                                })
                                            }

                                            return (
                                                <div>
                                                    {lastMonth == null ? (
                                                        <>
                                                            <img
                                                                src={questions}
                                                                style={{ margin: "19px" }}
                                                            />
                                                            <div>データなし</div>
                                                        </>
                                                    ) : (
                                                        <div style={{ margin: "7px" }}>
                                                            {rank + 1 == 1 && (
                                                                <PictureAnimation>
                                                                    <img
                                                                        src={month1}
                                                                        alt="month1"
                                                                        style={{
                                                                            width: "35%",
                                                                            maxWidth: "50px",
                                                                            marginBottom: "9px",
                                                                        }}
                                                                    />
                                                                </PictureAnimation>
                                                            )}
                                                            {rank + 1 == 2 && (
                                                                <PictureAnimation>
                                                                    <img
                                                                        src={month2}
                                                                        alt="month2"
                                                                        style={{
                                                                            width: "35%",
                                                                            maxWidth: "50px",
                                                                            marginBottom: "9px",
                                                                        }}
                                                                    />
                                                                </PictureAnimation>
                                                            )}
                                                            {rank + 1 == 3 && (
                                                                <PictureAnimation>
                                                                    <img
                                                                        src={month3}
                                                                        alt="month3"
                                                                        style={{
                                                                            width: "35%",
                                                                            maxWidth: "50px",
                                                                            marginBottom: "9px",
                                                                        }}
                                                                    />
                                                                </PictureAnimation>
                                                            )}
                                                            {rank + 1 == 4 && (
                                                                <PictureAnimation>
                                                                    <img
                                                                        src={month4}
                                                                        alt="month4"
                                                                        style={{
                                                                            width: "35%",
                                                                            maxWidth: "50px",
                                                                            marginBottom: "9px",
                                                                        }}
                                                                    />
                                                                </PictureAnimation>
                                                            )}
                                                            {rank + 1 == 5 && (
                                                                <PictureAnimation>
                                                                    <img
                                                                        src={month5}
                                                                        alt="month5"
                                                                        style={{
                                                                            width: "35%",
                                                                            maxWidth: "50px",
                                                                            marginBottom: "9px",
                                                                        }}
                                                                    />
                                                                </PictureAnimation>
                                                            )}
                                                            {rank + 1 > 5 && (
                                                                <img
                                                                    src={month000}
                                                                    alt="month000"
                                                                    style={{
                                                                        width: "35%",
                                                                        maxWidth: "50px",
                                                                        marginBottom: "9px",
                                                                    }}
                                                                />
                                                            )}
                                                            <PictureAnimation>
                                                                {ranking}
                                                            </PictureAnimation>
                                                            <div>{lastMonth}% </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        })()}
                                    </CardContent>
                                    <CardContent
                                        className={classes.cardInset}
                                        style={{ paddingBottom: "16px", width: "100%" }}
                                    >
                                        <div>先月</div>
                                        {(() => {
                                            let year = moment(moment().subtract(1, "M")).format(
                                                "YYYY"
                                            )
                                            let month = moment(moment().subtract(1, "M")).format(
                                                "M"
                                            )
                                            let lastMonth = monthHitRate(year, month, allRecords)
                                            let rank
                                            let ranking
                                            if (lastMonth !== null) {
                                                let memberLastMonth = memberRecords.map((data) => {
                                                    return monthHitRate(year, month, data)
                                                })
                                                memberLastMonth.sort(compare)
                                                memberLastMonth.reverse()
                                                rank = memberLastMonth.findIndex((v) => {
                                                    return v === lastMonth
                                                })
                                                let num = 0
                                                rankPic.forEach((value) => {
                                                    if (num <= lastMonth && lastMonth < num + 5) {
                                                        ranking = (
                                                            <img
                                                                src={value}
                                                                style={{
                                                                    width: "65%",
                                                                    maxWidth: "90px",
                                                                }}
                                                                alt={value}
                                                                onClick={() => {
                                                                    setRankDetailsOpen(true)
                                                                }}
                                                            />
                                                        )
                                                    }
                                                    num = num + 5
                                                })
                                            }

                                            return (
                                                <div>
                                                    {lastMonth == null ? (
                                                        <>
                                                            <img
                                                                src={questions}
                                                                style={{ margin: "19px" }}
                                                            />
                                                            <div>データなし</div>
                                                        </>
                                                    ) : (
                                                        <div style={{ margin: "7px" }}>
                                                            {rank + 1 == 1 && (
                                                                <PictureAnimation>
                                                                    <img
                                                                        src={month1}
                                                                        alt="month1"
                                                                        style={{
                                                                            width: "35%",
                                                                            maxWidth: "50px",
                                                                            marginBottom: "9px",
                                                                        }}
                                                                    />
                                                                </PictureAnimation>
                                                            )}
                                                            {rank + 1 == 2 && (
                                                                <PictureAnimation>
                                                                    <img
                                                                        src={month2}
                                                                        alt="month2"
                                                                        style={{
                                                                            width: "35%",
                                                                            maxWidth: "50px",
                                                                            marginBottom: "9px",
                                                                        }}
                                                                    />
                                                                </PictureAnimation>
                                                            )}
                                                            {rank + 1 == 3 && (
                                                                <PictureAnimation>
                                                                    <img
                                                                        src={month3}
                                                                        alt="month3"
                                                                        style={{
                                                                            width: "35%",
                                                                            maxWidth: "50px",
                                                                            marginBottom: "9px",
                                                                        }}
                                                                    />
                                                                </PictureAnimation>
                                                            )}
                                                            {rank + 1 == 4 && (
                                                                <PictureAnimation>
                                                                    <img
                                                                        src={month4}
                                                                        alt="month4"
                                                                        style={{
                                                                            width: "35%",
                                                                            maxWidth: "50px",
                                                                            marginBottom: "9px",
                                                                        }}
                                                                    />
                                                                </PictureAnimation>
                                                            )}
                                                            {rank + 1 == 5 && (
                                                                <PictureAnimation>
                                                                    <img
                                                                        src={month5}
                                                                        alt="month5"
                                                                        style={{
                                                                            width: "35%",
                                                                            maxWidth: "50px",
                                                                            marginBottom: "9px",
                                                                        }}
                                                                    />
                                                                </PictureAnimation>
                                                            )}
                                                            {rank + 1 > 5 && (
                                                                <img
                                                                    src={month000}
                                                                    style={{
                                                                        width: "35%",
                                                                        maxWidth: "50px",
                                                                        marginBottom: "9px",
                                                                    }}
                                                                    alt="month000"
                                                                />
                                                            )}
                                                            <PictureAnimation>
                                                                {ranking}
                                                            </PictureAnimation>
                                                            <div>{lastMonth}% </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        })()}
                                    </CardContent>
                                </Card>
                                {loginUser.id == fetchUser.id && (
                                    <>
                                        <div className={classes.itemTitle}>これまでの総記録</div>
                                        <Card style={{ display: "flex", flexWrap: "wrap" }}>
                                            <div style={{ textAlign: "center" }}>
                                                <div
                                                    style={{
                                                        display: "inline-flex",
                                                        margin: "4px",
                                                    }}
                                                >
                                                    {(() => {
                                                        let records = allHitRate(allRecords.records)
                                                        return (
                                                            <>
                                                                <div style={{ margin: "7px" }}>
                                                                    <div> 通常練習 総本数</div>
                                                                    <CardContent
                                                                        className={
                                                                            classes.cardInset2
                                                                        }
                                                                        style={{
                                                                            paddingBottom: "0px",
                                                                        }}
                                                                    >
                                                                        {records.all_arrow}本
                                                                    </CardContent>
                                                                </div>
                                                                <div style={{ margin: "7px" }}>
                                                                    <div>通常練習 的中率</div>
                                                                    <CardContent
                                                                        className={
                                                                            classes.cardInset2
                                                                        }
                                                                        style={{
                                                                            paddingBottom: "0px",
                                                                        }}
                                                                    >
                                                                        {records.hit_rate}%
                                                                    </CardContent>
                                                                </div>
                                                            </>
                                                        )
                                                    })()}
                                                </div>
                                                <div
                                                    style={{
                                                        display: "inline-flex",
                                                        margin: "4px",
                                                    }}
                                                >
                                                    {(() => {
                                                        let records = allHitRate(
                                                            allRecords.records2
                                                        )
                                                        return (
                                                            <>
                                                                <div style={{ margin: "7px" }}>
                                                                    <div> 強化練習 総本数</div>
                                                                    <CardContent
                                                                        className={
                                                                            classes.cardInset2
                                                                        }
                                                                        style={{
                                                                            paddingBottom: "0px",
                                                                        }}
                                                                    >
                                                                        {records.all_arrow}本
                                                                    </CardContent>
                                                                </div>
                                                                <div style={{ margin: "7px" }}>
                                                                    <div>強化練習 的中率</div>
                                                                    <CardContent
                                                                        className={
                                                                            classes.cardInset2
                                                                        }
                                                                        style={{
                                                                            paddingBottom: "0px",
                                                                        }}
                                                                    >
                                                                        {records.hit_rate}%
                                                                    </CardContent>
                                                                </div>
                                                            </>
                                                        )
                                                    })()}
                                                </div>
                                                <div
                                                    style={{
                                                        display: "inline-flex",
                                                        margin: "4px",
                                                    }}
                                                >
                                                    {(() => {
                                                        let records = allHitRate(
                                                            allRecords.records3
                                                        )
                                                        return (
                                                            <>
                                                                <div style={{ margin: "7px" }}>
                                                                    <div> 自主練習 総本数</div>
                                                                    <CardContent
                                                                        className={
                                                                            classes.cardInset2
                                                                        }
                                                                        style={{
                                                                            paddingBottom: "0px",
                                                                        }}
                                                                    >
                                                                        {records.all_arrow}本
                                                                    </CardContent>
                                                                </div>
                                                                <div style={{ margin: "7px" }}>
                                                                    <div>自主練習 的中率</div>
                                                                    <CardContent
                                                                        className={
                                                                            classes.cardInset2
                                                                        }
                                                                        style={{
                                                                            paddingBottom: "0px",
                                                                        }}
                                                                    >
                                                                        {records.hit_rate}%
                                                                    </CardContent>
                                                                </div>
                                                            </>
                                                        )
                                                    })()}
                                                </div>
                                            </div>
                                        </Card>
                                    </>
                                )}
                                <div className={classes.itemTitle}>獲得バッジ</div>
                                <Card style={{ display: "flex" }}>
                                    <CardContent
                                        className={classes.cardInset}
                                        style={{ padding: "10px" }}
                                    >
                                        {(() => {
                                            ////////console.log(bestMonth);
                                            return (
                                                <>
                                                    {bestMonth !== null ? (
                                                        <>
                                                            {bestMonth.hit_rate < 75 ? (
                                                                <>
                                                                    <img
                                                                        src={questions}
                                                                        style={{ margin: "6px" }}
                                                                        alt="questions"
                                                                    />
                                                                    <div>未獲得</div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <PictureAnimation>
                                                                        {" "}
                                                                        <img src={hitRate} />{" "}
                                                                    </PictureAnimation>
                                                                    <div
                                                                        style={{
                                                                            fontSize: "10px",
                                                                            margin: "-4px",
                                                                        }}
                                                                    >
                                                                        通常練習的中率
                                                                        <br />
                                                                        75%の称号
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <img
                                                                src={questions}
                                                                style={{ margin: "6px" }}
                                                                alt="questions"
                                                            />
                                                            <div>未獲得</div>
                                                        </>
                                                    )}
                                                </>
                                            )
                                        })()}
                                    </CardContent>

                                    <CardContent
                                        className={classes.cardInset}
                                        style={{ padding: "10px" }}
                                    >
                                        {(() => {
                                            let records = allHitRate(allRecords.records)
                                            let records2 = allHitRate(allRecords.records2)
                                            let records3 = allHitRate(allRecords.records3)
                                            ////////console.log(records.hit_arrow + records2.hit_arrow + records3.hit_arrow)
                                            return (
                                                <>
                                                    {records !== null &&
                                                    records2 !== null &&
                                                    records3 !== null ? (
                                                        <>
                                                            {records.hit_arrow +
                                                                records2.hit_arrow +
                                                                records3.hit_arrow <
                                                            1000 ? (
                                                                <>
                                                                    <img
                                                                        src={questions}
                                                                        style={{ margin: "6px" }}
                                                                        alt="questions"
                                                                    />
                                                                    <div>未獲得</div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <PictureAnimation>
                                                                        {" "}
                                                                        <img src={hitAll} />{" "}
                                                                    </PictureAnimation>
                                                                    <div
                                                                        style={{
                                                                            fontSize: "10px",
                                                                            margin: "-4px",
                                                                        }}
                                                                    >
                                                                        総的中数
                                                                        <br />
                                                                        1000本の称号
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <img
                                                                src={questions}
                                                                style={{ margin: "6px" }}
                                                                alt="questions"
                                                            />
                                                            <div>未獲得</div>
                                                        </>
                                                    )}
                                                </>
                                            )
                                        })()}
                                    </CardContent>
                                    <CardContent
                                        className={classes.cardInset}
                                        style={{ padding: "10px" }}
                                    >
                                        {(() => {
                                            let records3 = allHitRate(allRecords.records3)
                                            return (
                                                <>
                                                    {records3 !== null ? (
                                                        <>
                                                            {records3.all_arrow < 1000 ? (
                                                                <>
                                                                    <img
                                                                        src={questions}
                                                                        style={{ margin: "6px" }}
                                                                        alt="questions"
                                                                    />
                                                                    <div>未獲得</div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <PictureAnimation>
                                                                        {" "}
                                                                        <img
                                                                            src={selfPractice}
                                                                            alt="selfPractice"
                                                                        />{" "}
                                                                    </PictureAnimation>
                                                                    <div
                                                                        style={{
                                                                            fontSize: "10px",
                                                                            margin: "-4px",
                                                                        }}
                                                                    >
                                                                        自主練の総本数
                                                                        <br />
                                                                        1000本の称号
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <img
                                                                src={questions}
                                                                style={{ margin: "6px" }}
                                                                alt="questions"
                                                            />
                                                            <div>未獲得</div>
                                                        </>
                                                    )}
                                                </>
                                            )
                                        })()}
                                    </CardContent>
                                </Card>
                                {/* <div style={{ marginBottom: "50px" }}></div> */}
                            </div>
                        </div>
                    </div>
                )
                // :
                // <WaitCircle />
            }
        </>
    )
}
