import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Slide from "@material-ui/core/Slide"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import rank0 from "src/images/rankPic/rank0.png"
import rank10 from "src/images/rankPic/rank10.png"
import rank100 from "src/images/rankPic/rank100.png"
import rank15 from "src/images/rankPic/rank15.png"
import rank20 from "src/images/rankPic/rank20.png"
import rank25 from "src/images/rankPic/rank25.png"
import rank30 from "src/images/rankPic/rank30.png"
import rank35 from "src/images/rankPic/rank35.png"
import rank40 from "src/images/rankPic/rank40.png"
import rank45 from "src/images/rankPic/rank45.png"
import rank5 from "src/images/rankPic/rank5.png"
import rank50 from "src/images/rankPic/rank50.png"
import rank55 from "src/images/rankPic/rank55.png"
import rank60 from "src/images/rankPic/rank60.png"
import rank65 from "src/images/rankPic/rank65.png"
import rank70 from "src/images/rankPic/rank70.png"
import rank75 from "src/images/rankPic/rank75.png"
import rank80 from "src/images/rankPic/rank80.png"
import rank85 from "src/images/rankPic/rank85.png"
import rank90 from "src/images/rankPic/rank90.png"
import rank95 from "src/images/rankPic/rank95.png"

import Theme from "../../../entity/theme/theme"

const useStyles = makeStyles((theme) => ({
    appBar: {
        fontFamily: Theme.font.fontFamily,
        top: "auto",
        bottom: 0,
        background: Theme.color.primaryColor,
    },
    typography: {
        padding: theme.spacing(2),
        margin: "10px",
        width: "160px",
        fontWeight: "bold",
        fontFamily: Theme.font.fontFamily,
    },
    ranckPic: {
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "20px",
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function RankDetails(props) {
    const { loginUser, rankDetailsOpen, setRankDetailsOpen } = props
    const classes = useStyles()
    const [rate] = React.useState([
        100, 95, 90, 85, 80, 75, 70, 65, 60, 55, 50, 45, 40, 35, 30, 25, 20, 15, 10, 5, 0,
    ])
    const [open, setOpen] = React.useState(false)
    const [rankPic] = React.useState([
        rank100,
        rank95,
        rank90,
        rank85,
        rank80,
        rank75,
        rank70,
        rank65,
        rank60,
        rank55,
        rank50,
        rank45,
        rank40,
        rank35,
        rank30,
        rank25,
        rank20,
        rank15,
        rank10,
        rank5,
        rank0,
    ])

    const handleScroll = () => {
        if (open) {
            setOpen(false)
        }
    }

    return (
        <>
            {loginUser && (
                <div onScroll={handleScroll}>
                    <Dialog
                        disableBackdropClick
                        disableEscapeKeyDown
                        open={rankDetailsOpen}
                        fullScreen
                        onClose={() => {
                            setRankDetailsOpen(false)
                        }}
                        TransitionComponent={Transition}
                    >
                        <Button
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                setRankDetailsOpen(false)
                                setOpen(false)
                            }}
                            aria-label="close"
                        >
                            <AppBar className={classes.appBar}>
                                <Typography variant="h6" style={{ margin: "10px 5px" }}>
                                    <div style={{ textAlign: "center" }}> 閉じる</div>
                                </Typography>
                            </AppBar>
                        </Button>
                        {rankPic.map((value, index) => {
                            return (
                                <div className={classes.ranckPic} key={"rankPic" + index}>
                                    <img
                                        src={value}
                                        alt={value}
                                        style={{ width: "30%", height: "auto", maxWidth: "150px" }}
                                    />
                                    <div style={{ margin: "30px 10px" }}>
                                        {index == 0 ? (
                                            <>{rate[0] + "%"}</>
                                        ) : (
                                            <>{rate[index] + "～" + (rate[index - 1] - 1) + "%"}</>
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                        <div style={{ marginBottom: "50px" }} />
                    </Dialog>
                </div>
            )}
        </>
    )
}
