import Dialog from "@material-ui/core/Dialog"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { withStyles } from "@material-ui/core/styles"
import AccountCircle from "@material-ui/icons/AccountCircle"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import RefreshIcon from "@material-ui/icons/Refresh"
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle"
import { navigate } from "gatsby"
import React from "react"
import fontStyle from "src/entity/theme/fontStyle"

import MemberProfile from "../profile/memberProfile"
import Profile from "../profile/profile"

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
))

export default function MenuContent(props) {
    const {
        memberData,
        setMemberData,
        loginUser,
        setLoginUser,
        handleClose,
        anchorEl,
        menuContentOpend,
        setMenuContentOpend,
    } = props

    const menuContentClose = () => {
        setMenuContentOpend([false, false, false, false])
    }

    const Logout = () => {
        localStorage.clear()
        navigate("/")
        setTimeout(() => {
            window.location.reload(false)
        }, 500)
    }
    const Refresh = () => {
        window.location.reload(false)
    }

    return (
        <div>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                    handleClose()
                }}
            >
                <MenuItem
                    onClick={() => {
                        if (menuContentOpend[0] == false) {
                            setMenuContentOpend([true, false, false, false])
                        }
                    }}
                >
                    <ListItemIcon>
                        <AccountCircle />
                    </ListItemIcon>
                    <ListItemText
                        primary="プロフィール"
                        primaryTypographyProps={{ style: fontStyle("500") }}
                    />
                    <Dialog
                        disableBackdropClick
                        disableEscapeKeyDown
                        open={menuContentOpend[0]}
                        onClose={menuContentClose}
                        fullWidth
                    >
                        <Profile
                            memberData={memberData}
                            setMemberData={setMemberData}
                            loginUser={loginUser}
                            setLoginUser={setLoginUser}
                            fetchUser={loginUser}
                            onClose={menuContentClose}
                            profileOpenFlag={menuContentOpend[0]}
                        />
                    </Dialog>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        if (menuContentOpend[2] == false) {
                            setMenuContentOpend([false, false, true, false])
                        }
                    }}
                >
                    <ListItemIcon>
                        <SupervisedUserCircleIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="部員プロフィール"
                        primaryTypographyProps={{ style: fontStyle("500") }}
                    />
                    <Dialog
                        disableBackdropClick
                        disableEscapeKeyDown
                        open={menuContentOpend[2]}
                        onClose={menuContentClose}
                        fullWidth
                    >
                        <MemberProfile
                            memberData={memberData}
                            setMemberData={setMemberData}
                            loginUser={loginUser}
                            setLoginUser={setLoginUser}
                            onClose={menuContentClose}
                        />
                    </Dialog>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        Refresh()
                    }}
                >
                    <ListItemIcon>
                        <RefreshIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="更新"
                        primaryTypographyProps={{ style: fontStyle("500") }}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        Logout()
                    }}
                >
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="ログアウト"
                        primaryTypographyProps={{ style: fontStyle("500") }}
                    />
                </MenuItem>
            </StyledMenu>
        </div>
    )
}
