import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import { makeStyles } from "@material-ui/core/styles"
import { navigate } from "gatsby"
import moment from "moment"
import React from "react"
import SwipeableViews from "react-swipeable-views"
import fontStyle from "src/entity/theme/fontStyle"
import swal from "sweetalert"

import {
    sendSuccess,
    doubleWarning,
    hitWarning,
    inputWarning,
    sendError,
} from "../../../entity/alert/swalErrorList"
import Theme from "../../../entity/theme/theme"
import { recordsRepository } from "../../../repository/action"

import DatePicker from "./datePicker"

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: Theme.font.fontFamily,
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
    formControl: {
        margin: theme.spacing(0.5),
        minWidth: 120,
        fontFamily: Theme.font.fontFamily,
    },
    buttonPosition: {
        textAlign: "center",
    },

    upForm: {
        borderRight: "5px solid",
    },
    "@keyframes up ": {
        from: {
            transform: "translateY(0)",
        },
        to: {
            transform: "translateY(-700px)",
        },
    },
    dialogTitle: {
        display: "inline-block",
        padding: "16px 8px 0px",
    },
    title: {
        display: "inline-block",
        bottom: "8px",
        position: "relative",
        fontWeight: "bold",
        fontFamily: Theme.font.fontFamily,
    },
    checkColor: {
        color: "#4caf50",
    },
    subTitle: {
        fontWeight: "bold",
        fontFamily: Theme.font.fontFamily,
    },
    mediaScreen: {
        "@media screen and (min-width:600px) ": {
            padding: "4px",
        },
    },
}))

export default function HitWriteDialog(props) {
    const classes = useStyles()
    const {
        loginUser,
        memberData,
        setIsLoading,
        title,
        setTitle,
        open,
        openClose,
        setRecords,
        setMonthAllRecords,
        setDistinction,
        setRangeDate,
    } = props
    const [hitArrow, setHitArrow] = React.useState("")
    const [allArrow, setAllArrow] = React.useState(20)
    const [selectedDate, setSelectedDate] = React.useState(new Date())
    const list = [...Array(301).keys()]
    const list2 = [...Array(301).keys()]
    const [bool, setBool] = React.useState([true, false])
    const [activeStep, setActiveStep] = React.useState(0)
    const [buttonColors, setButtonColors] = React.useState(["primary", "default", "default"])
    const [classification, setClassification] = React.useState("通常練習")

    const handleHitChange = (event) => {
        setHitArrow(Number(event.target.value))
        if (Number(event.target.value) >= 0) {
            let disabled = [...bool]
            disabled[0] = false
            setBool([...disabled])
        } else {
            let disabled = [...bool]
            disabled[0] = true
            setBool([...disabled])
        }
    }

    const handleAllChange = (event) => {
        setAllArrow(Number(event.target.value))
    }

    const activeStepChange = (step) => {
        setActiveStep(step)
        setHitArrow("")
        setAllArrow(20)
        setBool([true, false])
        if (step == 0) {
            setButtonColors(["primary", "default", "default"])
            setClassification("通常練習")
        } else if (step == 1) {
            setButtonColors(["default", "primary", "default"])
            setClassification("強化練習")
        } else if (step == 2) {
            setButtonColors(["default", "default", "primary"])
            setClassification("自主練習")
        }
    }

    const handleDateChange = (date) => {
        setSelectedDate(date)
        //////////console.log(date);
        if (date === null || String(date) == "Invalid Date") {
            let disabled = [...bool]
            disabled[1] = true
            setBool([...disabled])
        } else {
            let disabled = [...bool]
            disabled[1] = false
            setBool([...disabled])
        }
    }

    React.useEffect(() => {
        if (title) {
            switch (title) {
                case "通常練習 記録":
                    setButtonColors(["primary", "default", "default"])
                    setClassification("通常練習")
                    setActiveStep(0)
                    break
                case "強化練習 記録":
                    setButtonColors(["default", "primary", "default"])
                    setClassification("強化練習")
                    setActiveStep(1)
                    break
                case "自主練習 記録":
                    setButtonColors(["default", "default", "primary"])
                    setClassification("自主練習")
                    setActiveStep(2)
                    break
            }
        }
    }, [])

    ////////console.log({ activeStep, hitArrow, allArrow, open })
    return (
        <div className={classes.root}>
            <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={openClose}>
                <div style={{ display: "inline", textAlign: "center", maxWidth: "595px" }}>
                    <DialogTitle className={classes.dialogTitle}>
                        <Button
                            className={classes.title}
                            color={buttonColors[0]}
                            onClick={() => {
                                activeStepChange(0)
                            }}
                        >
                            <div style={{ display: "inline-block" }}>
                                <div style={fontStyle("bold")}>通常練習</div>
                            </div>
                        </Button>
                    </DialogTitle>
                    <DialogTitle className={classes.dialogTitle}>
                        <Button
                            className={classes.title}
                            color={buttonColors[1]}
                            onClick={() => {
                                activeStepChange(1)
                            }}
                        >
                            <div style={{ display: "inline-block" }}>
                                {" "}
                                <div style={fontStyle("bold")}>強化練習</div>
                            </div>
                        </Button>
                    </DialogTitle>
                    <DialogTitle className={classes.dialogTitle}>
                        <Button
                            className={classes.title}
                            color={buttonColors[2]}
                            onClick={() => {
                                activeStepChange(2)
                            }}
                        >
                            <div style={{ display: "inline-block" }}>
                                {" "}
                                <div style={fontStyle("bold")}>自主練習</div>
                            </div>
                        </Button>
                    </DialogTitle>
                </div>
                <SwipeableViews index={activeStep} onChangeIndex={activeStepChange}>
                    <div className={classes.subTitle} style={Object.assign({})}>
                        <DialogContent>
                            <form className={classes.container}>
                                <DatePicker
                                    handleDateChange={handleDateChange}
                                    selectedDate={selectedDate}
                                    loginUser={loginUser}
                                    classification={classification}
                                />
                                <div className={classes.mediaScreen}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel htmlFor="demo-dialog-native">的中数</InputLabel>
                                        <Select
                                            native
                                            value={hitArrow}
                                            onChange={handleHitChange}
                                            input={<Input id="demo-dialog-native" />}
                                        >
                                            <option value="" />
                                            {list.map((num, index) => {
                                                return (
                                                    <option value={num} key={index}>
                                                        {num}
                                                    </option>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel htmlFor="demo-dialog-native">本数</InputLabel>
                                        <Select
                                            native
                                            value={allArrow}
                                            onChange={handleAllChange}
                                            input={<Input id="demo-dialog-native" />}
                                        >
                                            {/* <option value="" /> */}
                                            {list.map((num, index) => {
                                                return (
                                                    <option value={num} key={index}>
                                                        {num}
                                                    </option>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={openClose} color="primary">
                                <div style={fontStyle("bold")}> 閉じる</div>
                            </Button>
                            <Button
                                disabled={bool.findIndex((b) => b) !== -1}
                                onClick={() => {
                                    if (hitArrow < 0) {
                                        swal(hitWarning)
                                    } else if (hitArrow > allArrow) {
                                        swal(inputWarning)
                                    } else {
                                        let distinction = 0
                                        setIsLoading(true)
                                        openClose()
                                        recordsRepository
                                            .hitWriteAllMonthDataFetch(
                                                loginUser,
                                                selectedDate,
                                                "records",
                                                hitArrow,
                                                allArrow
                                            )
                                            .then((data) => {
                                                if (data.doubleRecords == "doubleRecords") {
                                                    swal(doubleWarning)
                                                } else {
                                                    swal(sendSuccess)
                                                }
                                                if (
                                                    window.location.pathname.split("/")[1] ==
                                                    "records"
                                                ) {
                                                    setMonthAllRecords(data)
                                                    setRecords(data["records"])
                                                    setIsLoading(false)
                                                    setTitle("通常練習 記録")
                                                    setDistinction(distinction)
                                                    setRangeDate({
                                                        startDate: moment().startOf("month"),
                                                        endDate: moment(),
                                                    })
                                                } else {
                                                    navigate(`/records`, {
                                                        state: {
                                                            monthAllRecords: data,
                                                            loginUser,
                                                            memberData,
                                                            distinction,
                                                        },
                                                    })
                                                }
                                            })
                                            .catch(() => {
                                                swal(sendError).then(() => {
                                                    setIsLoading(false)
                                                    localStorage.clear()
                                                    navigate("/")
                                                })
                                            })
                                    }
                                }}
                                color="primary"
                            >
                                <div style={fontStyle("bold")}> 提出</div>
                            </Button>
                        </DialogActions>
                        {/* </Animation> */}
                    </div>
                    <div className={classes.subTitle} style={Object.assign({})}>
                        <DialogContent>
                            <form className={classes.container}>
                                <DatePicker
                                    handleDateChange={handleDateChange}
                                    selectedDate={selectedDate}
                                    loginUser={loginUser}
                                    classification={classification}
                                />
                                <div className={classes.mediaScreen}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel htmlFor="demo-dialog-native">的中数</InputLabel>
                                        <Select
                                            native
                                            value={hitArrow}
                                            onChange={handleHitChange}
                                            input={<Input id="demo-dialog-native" />}
                                        >
                                            <option value="" />
                                            {list2.map((num, index) => {
                                                return (
                                                    <option value={num} key={index}>
                                                        {num}
                                                    </option>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel htmlFor="demo-dialog-native">本数</InputLabel>
                                        <Select
                                            native
                                            value={allArrow}
                                            onChange={handleAllChange}
                                            input={<Input id="demo-dialog-native" />}
                                        >
                                            {list2.map((num, index) => {
                                                return (
                                                    <option value={num} key={index}>
                                                        {num}
                                                    </option>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={openClose} color="primary">
                                <div style={fontStyle("bold")}> 閉じる</div>
                            </Button>
                            <Button
                                disabled={bool.findIndex((b) => b) !== -1}
                                onClick={() => {
                                    if (hitArrow < 0) {
                                        swal(hitWarning)
                                    } else if (hitArrow > allArrow) {
                                        swal(inputWarning)
                                    } else {
                                        let distinction = 1
                                        setIsLoading(true)
                                        openClose()
                                        recordsRepository
                                            .hitWriteAllMonthDataFetch(
                                                loginUser,
                                                selectedDate,
                                                "records2",
                                                hitArrow,
                                                allArrow
                                            )
                                            .then((data) => {
                                                if (data.doubleRecords == "doubleRecords") {
                                                    swal(doubleWarning)
                                                } else {
                                                    swal(sendSuccess)
                                                }
                                                if (
                                                    window.location.pathname.split("/")[1] ==
                                                    "records"
                                                ) {
                                                    setMonthAllRecords(data)
                                                    setRecords(data["records2"])
                                                    setIsLoading(false)
                                                    setTitle("強化練習 記録")
                                                    setDistinction(distinction)
                                                    setRangeDate({
                                                        startDate: moment().startOf("month"),
                                                        endDate: moment(),
                                                    })
                                                } else {
                                                    navigate(`/records`, {
                                                        state: {
                                                            monthAllRecords: data,
                                                            loginUser,
                                                            memberData,
                                                            distinction,
                                                        },
                                                    })
                                                }
                                            })
                                            .catch(() => {
                                                swal(sendError).then(() => {
                                                    setIsLoading(false)
                                                    localStorage.clear()
                                                    navigate("/")
                                                })
                                            })
                                    }
                                }}
                                color="primary"
                            >
                                <div style={fontStyle("bold")}> 提出</div>
                            </Button>
                        </DialogActions>
                    </div>
                    <div className={classes.subTitle} style={Object.assign({})}>
                        <DialogContent>
                            <form className={classes.container}>
                                <DatePicker
                                    handleDateChange={handleDateChange}
                                    selectedDate={selectedDate}
                                    loginUser={loginUser}
                                    classification={classification}
                                />
                                <div className={classes.mediaScreen}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel htmlFor="demo-dialog-native">的中数</InputLabel>
                                        <Select
                                            native
                                            value={hitArrow}
                                            onChange={handleHitChange}
                                            input={<Input id="demo-dialog-native" />}
                                        >
                                            <option value="" />
                                            {list2.map((num, index) => {
                                                return (
                                                    <option value={num} key={index}>
                                                        {num}
                                                    </option>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel htmlFor="demo-dialog-native">本数</InputLabel>
                                        <Select
                                            native
                                            value={allArrow}
                                            onChange={handleAllChange}
                                            input={<Input id="demo-dialog-native" />}
                                        >
                                            {list2.map((num, index) => {
                                                return (
                                                    <option value={num} key={index}>
                                                        {num}
                                                    </option>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={openClose} color="primary">
                                <div style={fontStyle("bold")}> 閉じる</div>
                            </Button>
                            <Button
                                disabled={bool.findIndex((b) => b) !== -1}
                                onClick={() => {
                                    if (hitArrow < 0) {
                                        swal(hitWarning)
                                    } else if (hitArrow > allArrow) {
                                        swal(inputWarning)
                                    } else {
                                        let distinction = 2
                                        setIsLoading(true)
                                        openClose()
                                        recordsRepository
                                            .hitWriteAllMonthDataFetch(
                                                loginUser,
                                                selectedDate,
                                                "records3",
                                                hitArrow,
                                                allArrow
                                            )
                                            .then((data) => {
                                                if (data.doubleRecords == "doubleRecords") {
                                                    swal(doubleWarning)
                                                } else {
                                                    swal(sendSuccess)
                                                }
                                                if (
                                                    window.location.pathname.split("/")[1] ==
                                                    "records"
                                                ) {
                                                    setMonthAllRecords(data)
                                                    setRecords(data["records3"])
                                                    setIsLoading(false)
                                                    setTitle("自主練習 記録")
                                                    setDistinction(distinction)
                                                    setRangeDate({
                                                        startDate: moment().startOf("month"),
                                                        endDate: moment(),
                                                    })
                                                } else {
                                                    navigate(`/records`, {
                                                        state: {
                                                            monthAllRecords: data,
                                                            loginUser,
                                                            memberData,
                                                            distinction,
                                                        },
                                                    })
                                                }
                                            })
                                            .catch(() => {
                                                swal(sendError).then(() => {
                                                    setIsLoading(false)
                                                    localStorage.clear()
                                                    navigate("/")
                                                })
                                            })
                                    }
                                }}
                                color="primary"
                            >
                                <div style={fontStyle("bold")}> 提出</div>
                            </Button>
                        </DialogActions>
                    </div>
                </SwipeableViews>
            </Dialog>
        </div>
    )
}
