import Theme from "./theme"

/**
 * fontサイズを自由に変えれる関数
 * @param weight fontSizeの大きさ
 */

const fontStyle = (weight: string) => {
    return {
        fontFamily: Theme.font.fontFamily,
        fontWeight: weight,
    }
}

export default fontStyle
