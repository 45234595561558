import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import React from "react"

import Theme from "../../../entity/theme/theme"

const GreenCheckbox = withStyles({
    root: {
        color: "#4caf50",
        "&$checked": {
            color: "#4caf50",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />)

const useStyles = makeStyles(() => ({
    display: {
        display: "flex",
        marginRight: "4px",
        fontFamily: Theme.font.fontFamily,
        fontWeight: "500",
    },
    margin: {
        color: "rgba(0, 0, 0, 0.54)",
    },
}))

export default function MailSwitch(props) {
    const { mailState, setMailState, emailTitle } = props
    const classes = useStyles()
    const handleChange = (name) => (event) => {
        setMailState({ ...mailState, [name]: event.target.checked })
    }
    //////////console.log({ mailState })

    return (
        <FormGroup>
            <Typography component="div">
                <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                    style={{ margin: "0px" }}
                >
                    <Grid item className={classes.display}>
                        <MailOutlineIcon className={classes.margin} />
                        <span>{emailTitle}</span>
                    </Grid>
                    <Grid item style={{ padding: "0px 0px 0px 2px" }}>
                        <FormControlLabel
                            control={
                                <GreenCheckbox
                                    checked={mailState.checked}
                                    onChange={handleChange("checked")}
                                    name="checked"
                                />
                            }
                            //label="メール送信"
                        />
                    </Grid>
                </Grid>
            </Typography>
        </FormGroup>
    )
}
