import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import swal from "sweetalert"

import swalErrorAction from "../../../entity/alert/swalErrorAction"
import { sendSuccess, editWarning, networkError } from "../../../entity/alert/swalErrorList"
import Theme from "../../../entity/theme/theme"
import { attendanceRepository } from "../../../repository/action"

import AttendanceForm from "./attendanceForm"

import WaitCircle from "@/presentation/atoms/waitCircle"

const useStyles = makeStyles(() => ({
    container: {
        justifyContent: "center",
        marginTop: 32,
        display: "flex",
        flexWrap: "wrap",
        fontFamily: Theme.font.fontFamily,
    },
    buttons: {
        display: "flex",
        marginRight: "32px",
        justifyContent: "flex-end",
        marginBottom: "40px",
        marginTop: "30px",
        fontFamily: Theme.font.fontFamily,
    },
    button: {
        marginLeft: "10px",
        display: "inlineBlock",
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
}))

export default function AttendanceDialog(props) {
    const {
        loginUser,
        memberData,
        open,
        openClose,
        setAttendanceDay,
        setAllAttendanceDay,
        setExpanded,
        distribution,
    } = props
    const classes = useStyles()
    const [, setSendDate] = React.useState()
    const [attendance, setAttendance] = React.useState({ select: "欠席" })
    const [selectedDate, setSelectedDate] = React.useState(new Date())
    const [bool, setBool] = React.useState([false, true])
    const [linearAnimation, setLinearAnimation] = React.useState(false)
    const [mailState, setMailState] = React.useState({ checked: false })

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={openClose}>
            <div
                style={{
                    display: "inline",
                    textAlign: "center",
                    maxWidth: "595px",
                    overflowX: "hidden",
                }}
            >
                <AttendanceForm
                    setAttendance={setAttendance}
                    attendance={attendance}
                    setSendDate={setSendDate}
                    setBool={setBool}
                    bool={bool}
                    loginUser={loginUser}
                    memberData={memberData}
                    setMailState={setMailState}
                    mailState={mailState}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                />
                <div className={classes.buttons}>
                    <Button
                        className={classes.button}
                        color="primary"
                        onClick={() => {
                            if (Object.keys(attendance).length > 5) {
                                swal(editWarning).then((willClose) => {
                                    if (willClose) {
                                        openClose()
                                    }
                                })
                            } else {
                                openClose()
                            }
                        }}
                    >
                        閉じる
                    </Button>
                    <Button
                        className={classes.button}
                        color="primary"
                        disabled={bool.findIndex((b) => b) !== -1}
                        onClick={() => {
                            setBool(Array(2).fill(true))
                            setLinearAnimation(true)
                            attendanceRepository
                                .attendanceWrite(selectedDate, attendance, loginUser)
                                .then(() => {
                                    swal(sendSuccess)
                                    openClose()
                                    setLinearAnimation(false)
                                    if (mailState.checked) {
                                        attendanceRepository.attendancePushMail(
                                            selectedDate,
                                            attendance,
                                            loginUser,
                                            memberData
                                        )
                                    }
                                    if (window.location.pathname.split("/")[1] == "attendance") {
                                        attendanceRepository
                                            .attendanceDataFetch(loginUser, memberData)
                                            .then((data) => {
                                                setAllAttendanceDay(data)
                                                let attendanceArray = distribution(data)
                                                setAttendanceDay(attendanceArray)
                                                setExpanded([
                                                    Array(attendanceArray[0].length).fill(false),
                                                    Array(attendanceArray[1].length).fill(false),
                                                    Array(attendanceArray[2].length).fill(false),
                                                    Array(attendanceArray[3].length).fill(false),
                                                    Array(attendanceArray[4].length).fill(false),
                                                    Array(attendanceArray[5].length).fill(false),
                                                    Array(attendanceArray[6].length).fill(false),
                                                ])
                                                let attendanceJson = JSON.stringify(attendanceArray)
                                                localStorage.setItem(
                                                    ["attendance"],
                                                    [attendanceJson]
                                                )
                                            })
                                            .catch(() => {
                                                openClose()
                                                swalErrorAction(networkError)
                                            })
                                    }
                                })
                                .catch(() => {
                                    openClose()
                                    swalErrorAction(networkError)
                                })
                        }}
                    >
                        投稿する
                    </Button>
                    {linearAnimation && <WaitCircle height={"20px"} width={"20px"} />}
                </div>
            </div>
        </Dialog>
    )
}
