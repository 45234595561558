export default function DateChange(selectedDate) {
    let now = new Date(selectedDate)
    let year = now.getFullYear()
    let mon = now.getMonth() + 1
    let day = now.getDate()
    let dayOfWeek = now.getDay()
    let dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek]
    //let hour = now.getHours();
    //let min = now.getMinutes();
    //出力用
    let s = year + "年" + mon + "月" + day + "日" + "(" + dayOfWeekStr + ")"
    return s
}
