import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import { makeStyles } from "@material-ui/core/styles"
import loadImage from "blueimp-load-image"
import React from "react"
import swal from "sweetalert"

import swalErrorAction from "../../../entity/alert/swalErrorAction"
import { sendSuccess, editWarning, networkError } from "../../../entity/alert/swalErrorList"
import Theme from "../../../entity/theme/theme"
import { storage } from "../../../infra/firebase"
import { contactRepository } from "../../../repository/action"

import ContactForm from "./contactForm"

import WaitCircle from "@/presentation/atoms/waitCircle"

const shortid = require("shortid")

const useStyles = makeStyles(() => ({
    container: {
        justifyContent: "center",
        marginTop: 32,
        display: "flex",
        flexWrap: "wrap",
        fontFamily: Theme.font.fontFamily,
    },
    buttons: {
        display: "flex",
        marginRight: "32px",
        justifyContent: "flex-end",
        marginBottom: "40px",
        marginTop: "30px",
        fontFamily: Theme.font.fontFamily,
    },
    button: {
        marginLeft: "10px",
        display: "inlineBlock",
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
}))

export default function ContactDialog(props) {
    const { loginUser, memberData, setContacts, open, openClose } = props
    const classes = useStyles()
    const [, setSendDate] = React.useState()
    const [contact, setContact] = React.useState({ item: "重要連絡", itemColor: "red" })
    const [bool, setBool] = React.useState(Array(2).fill(true))
    const [linearAnimation, setLinearAnimation] = React.useState(false)
    const [mailState, setMailState] = React.useState({ checked: false })
    const [image, setImage] = React.useState([])
    // const [imageUrl, setImageUrl] = React.useState([])
    // const [percentFlag, setPercentFlag] = React.useState()
    const [imageName, setImageName] = React.useState([])
    let contactsjson
    let url = []

    React.useEffect(() => {
        if (image.length > 0) {
            let nameArray = image.map((value) => {
                return (
                    value.name.split(".")[0] +
                    "_" +
                    shortid.generate() +
                    "." +
                    value.name.split(".")[1]
                )
            })
            setImageName(nameArray)
        }
    }, [image])

    async function onSubmit() {
        // アップロード処理
        image.forEach(async (value, i, array) => {
            let canvas = await loadImage(value, {
                maxWidth: 1200,
                canvas: true,
            })
            await canvas.image.toBlob(async (blob) => {
                console.log(blob)
                storage
                    .ref(`/${loginUser.id.slice(0, 5) + "_images"}/${imageName[i]}`)
                    .put(blob)
                    .then((v) => {
                        if (v && i == array.length - 1) complete()
                    })
            }, value.type)
        })
    }

    const complete = () => {
        // 完了後の処理
        // 画像表示のため、アップロードした画像のURLを取得
        image.forEach(async (v, i, array) => {
            await storage
                .ref(loginUser.id.slice(0, 5) + "_images")
                .child(imageName[i])
                .getDownloadURL()
                .then((fireBaseUrl) => {
                    url = [...url, fireBaseUrl]
                    if (i === array.length - 1) {
                        contactSend(url)
                    }
                })
        })
    }

    const contactSend = (url) => {
        contactRepository
            .contactWrite(loginUser, contact, url)
            .then(() => {
                swal(sendSuccess)
                openClose()
                setLinearAnimation(false)
                if (mailState.checked === true) {
                    contactRepository.contactPushMail(loginUser, contact, memberData)
                }
                if (window.location.pathname.split("/")[1] == "") {
                    contactRepository
                        .contactsFetch(loginUser)
                        .then((data) => {
                            console.log(data)
                            contactsjson = JSON.stringify(data)
                            localStorage.setItem(["contacts"], [contactsjson])
                            setContacts(data.reverse())
                        })
                        .catch(() => {
                            openClose()
                            swalErrorAction(networkError)
                        })
                }
            })
            .catch(() => {
                openClose()
                swalErrorAction(networkError)
            })
    }

    const subumit = () => {
        //画像あり投稿
        if (image.length > 0) {
            onSubmit()
        }
        //画像なし投稿
        if (image.length == 0) {
            contactSend([])
        }
    }

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={openClose}>
            <div
                style={{
                    display: "inline",
                    textAlign: "center",
                    maxWidth: "595px",
                    overflowX: "hidden",
                }}
            >
                {/* {linearAnimation && <LinearProgress />} */}
                <ContactForm
                    setContact={setContact}
                    contact={contact}
                    setSendDate={setSendDate}
                    setBool={setBool}
                    bool={bool}
                    loginUser={loginUser}
                    setMailState={setMailState}
                    mailState={mailState}
                    image={image}
                    setImage={setImage}
                />
                <div className={classes.buttons}>
                    <Button
                        className={classes.button}
                        color="primary"
                        onClick={() => {
                            if (Object.keys(contact).length > 6) {
                                swal(editWarning).then((willClose) => {
                                    if (willClose) {
                                        openClose()
                                    }
                                })
                            } else {
                                openClose()
                            }
                        }}
                    >
                        閉じる
                    </Button>
                    <Button
                        className={classes.button}
                        color="primary"
                        disabled={bool.findIndex((b) => b) !== -1}
                        onClick={() => {
                            setBool(Array(2).fill(true))
                            setLinearAnimation(true)
                            subumit()
                        }}
                    >
                        投稿する
                    </Button>
                    {linearAnimation && <WaitCircle width={"20px"} height={"20px"} />}
                </div>
            </div>
        </Dialog>
    )
}
