import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import PhotoIcon from "@material-ui/icons/Photo"
import React from "react"

import Theme from "../../../entity/theme/theme"

export default function UploadPhoto(props) {
    const { image, setImage } = props
    const [beforeUrl, setBeforeUrl] = React.useState([])
    const font = (weight) => {
        return {
            fontFamily: Theme.font.fontFamily,
            fontWeight: weight,
            fontSize: "11px",
            color: "grey",
        }
    }
    const handleImage = (event) => {
        //  ////console.log(event.target.files);
        let imagePic = []
        let blobUrl = []
        if (event) {
            let eventNum = event.target.files.length
            // ////console.log(eventNum);
            for (let i = 0; i < eventNum; i++) {
                imagePic = [...imagePic, event.target.files[i]]
                blobUrl = [...blobUrl, window.URL.createObjectURL(event.target.files[i])]
            }
            setImage([...image, ...imagePic])
            setBeforeUrl([...beforeUrl, ...blobUrl])
        }
    }

    const imageDelete = (v, i) => {
        // ////console.log({ v })
        let deleteImage = image.filter((value, index) => index !== i)
        let deleteBeforeUrl = beforeUrl.filter((value) => value !== v)
        setImage(deleteImage)
        setBeforeUrl(deleteBeforeUrl)
    }

    // ////console.log({ image, beforeUrl })
    return (
        <div style={{ margin: "8px" }}>
            {beforeUrl.map((value, i) => {
                return (
                    <>
                        <div style={{ display: "flex" }}>
                            <img
                                style={{ height: "200px", width: "95%" }}
                                src={value}
                                alt="uploaded"
                            />
                            <IconButton
                                style={{ alignItems: "flex-start" }}
                                color="secondary"
                                onClick={() => {
                                    imageDelete(value, i)
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </>
                )
            })}

            <Button component="label" variant="outlined" style={{ marginTop: "30px" }}>
                <PhotoIcon /> 画像を添付
                <input
                    accept="image/*"
                    type="file"
                    name="image_file[]"
                    multiple="multiple"
                    style={{ display: "none" }}
                    onChange={handleImage}
                />
            </Button>
            <div style={font("500")}>
                *画像は縮小されるため、歪んで見える可能性がありますが投稿後は正常に見えます
            </div>
        </div>
    )
}
